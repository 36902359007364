// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* #####  Code for Printer Component Which Download Graph  ##### */

.links_div {
    box-sizing: border-box;
    display: flex;
    background-color: aliceblue;
    text-align: center;
    font-weight: 600;
    border-radius: 14px;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: 1px 2px 2px black;
    font-size: 0.7rem;
    z-index: 5;
    height: 0;
    overflow: hidden;
    transition: .2s ease; 

}

.links_div a {
    transition: .2s ease-in-out;
    text-decoration: none;
}

.links_div a:hover {
    color: rgb(236, 33, 15);
    color: rgb(240, 65, 17);
    scale: 1.010;
}`, "",{"version":3,"sources":["webpack://./src/components/printer.css"],"names":[],"mappings":";AACA,kEAAkE;;AAElE;IACI,sBAAsB;IACtB,aAAa;IACb,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,6BAA6B;IAC7B,iBAAiB;IACjB,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,oBAAoB;;AAExB;;AAEA;IACI,2BAA2B;IAC3B,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":["\n/* #####  Code for Printer Component Which Download Graph  ##### */\n\n.links_div {\n    box-sizing: border-box;\n    display: flex;\n    background-color: aliceblue;\n    text-align: center;\n    font-weight: 600;\n    border-radius: 14px;\n    flex-direction: column;\n    gap: 0.5rem;\n    box-shadow: 1px 2px 2px black;\n    font-size: 0.7rem;\n    z-index: 5;\n    height: 0;\n    overflow: hidden;\n    transition: .2s ease; \n\n}\n\n.links_div a {\n    transition: .2s ease-in-out;\n    text-decoration: none;\n}\n\n.links_div a:hover {\n    color: rgb(236, 33, 15);\n    color: rgb(240, 65, 17);\n    scale: 1.010;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
