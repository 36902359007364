import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { HeatMapData } from "../../../../actions/inverterActions";
import { useLocation } from "react-router-dom";
import SpinLoader from "../../../../components/SpinLoader";

/* ##### Heatmap Component  ##### */

const Heatmap = () => {
  const { heatmapData, loading } = useSelector((state) => state?.heatmapData);
  const dispatch = useDispatch();
  const location = useLocation();

  /* ###  Function To Get Inverter Number from URL   ### */

  const inverter = location.pathname?.split("/")[4];

  /* ###  End of Function To Get Inverter Number from URL   ### */

  useEffect(() => {
    dispatch(HeatMapData(inverter[inverter?.length - 1]));
  }, [dispatch, inverter]);
  return (
    <>
      {loading ? (
        <SpinLoader />
      ) : (
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          textAlign={"center"}
        >
          <Grid
            item
            sx={{ boxShadow: 2 }}
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              // borderRadius: "8px",
              margin: "1vmax auto",
              padding: "1vmax",
            }}
          >
            <Grid
              item
              lg={12}
              sx={{
                position: "relative",
                backgroundColor: "white",
                padding: "1rem 0",
                height: "85vh",
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              <h3
                style={{
                  position: "sticky",
                  top: "0",
                  left: "0",
                  right: "0",
                  padding: "0 1rem",
                  margin: "0",
                  width: "100%",
                }}
              >
                HeatMap Analysis For Inverter{inverter[inverter?.length - 1]}
              </h3>
              <Chart
                style={{
                  margin: "auto",
                  width: "95%",
                  minWidth: "500px",
                }}
                height={"95%"}
                type="heatmap"
                options={{
                  tooltip: {
                    y:{
                      formatter:(v)=>v==0?v:-v
                    }
                   
                  },

                  //   responsive: [
                  //     {
                  //       breakpoint: 1900,
                  //       options: {
                  //         chart: {
                  //           width: 1500,

                  //         },
                  //         legend: {
                  //           position: "top",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 1700,
                  //       options: {
                  //         chart: {
                  //           width: 1300,
                  //         },
                  //         legend: {
                  //           position: "top",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 1500,
                  //       options: {
                  //         chart: {
                  //           width: 1200,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 1300,
                  //       options: {
                  //         chart: {
                  //           width: 1150,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 1200,
                  //       options: {
                  //         chart: {
                  //           width: 1050,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 1100,
                  //       options: {
                  //         chart: {
                  //           width: 800,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 850,
                  //       options: {
                  //         chart: {
                  //           width: 600,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 700,
                  //       options: {
                  //         chart: {
                  //           width: 500,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //     {
                  //       breakpoint: 500,
                  //       options: {
                  //         chart: {
                  //           width: 300,
                  //         },
                  //         legend: {
                  //           position: "bottom",
                  //         },
                  //       },
                  //     },
                  //   ],

                  plotOptions: {
                    
                    heatmap: {
                      
                      // enableShades:true,
                      // distributed: true,
                      shadeIntensity: 1,
                      // reverseNegativeShade:false,
                      // useFillColorAsStroke: true,
                      colorScale: {
                      
                        inverse:true,
                        ranges: [
                          {
                            
                            from: 5,
                            to: 8,
                            name: "low",
                            color: "#00A100",
                          },
                          {
                            from: 9,
                            to: 11,
                            name: "medium",
                            color: "#128FD9",
                          },

                          {
                            from: 12,
                            to: 14,
                            name: "high",
                            color: "#FFB200",
                          },
                          {
                            from: 15,
                            to: 20,
                            name: "extreme",
                            color: "#FF0000",
                          },
                        ],
                      },
                    },
                  },
                  xaxis: {
                    labels: {
                      style: {
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        fontFamily:
                          "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
                      },
                    },
                  },
                  grid: {
                    show: true,
                    borderColor: "red",
                    
                  },
                  
                  dataLabels: {
                    
                    enabled: false,
                  },
                  // stroke: {
                  //     width: 5
                  // },

                  // title: {
                  //     text: 'HeatMap Chart with Color Range'
                  // },
                }}
                series={heatmapData?.data?.map(e=>{
                  return{
                    name:e["name"],
                    data:e["data"]?.map(ee=>{
                      return {
                        x:ee["x"],
                        y:ee["y"]==0?0:-ee["y"]
                      }
                    })
                  }
                }) || []}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Heatmap;
