import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InverterSmbMonthlyLoss,
  InverterSmbYearlyLoss,
} from "../../../../actions/inverterActions";
import SpinLoader from "../../../../components/SpinLoader";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors3 } from "../../../../colors/color";
import Printer from "../../../../components/Printer";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import { exportComponentAsJPEG } from "react-component-export-image";
import { getColourPicker, saveToSvg } from "../../../../constants/Utils";
import "./scbsmb.css";
import Printer2 from "../../../../components/Printer2";

const SCBSMB = () => {
  const [inverter, setInverter] = useState(0);
  const [smb, setSmb] = useState(0);

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showEfficiencyYearlyLoss, setShowEfficiencyYearlyLoss] = useState({
    loss: true,
    all: true,
  });

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showEfficiencyMonthlyLoss, setShowEfficiencyMonthlyLoss] = useState({
    smbNo1: true,
    smbNo2: true,
    smbNo3: true,
    smbNo4: true,
    smbNo5: true,
    smbNo6: true,
    smbNo7: true,
    smbNo8: true,
    smbNo9: true,
    smbNo10: true,
    smbNo11: true,
    smbNo12: true,
    all: true,
  });

  const { inverterSmbYearlyLoss, loading } = useSelector(
    (state) => state.inverterSmbYearlyLoss
  );
  const { inverterSmbMonthlyLoss } = useSelector(
    (state) => state.inverterSmbMonthlyLoss
  );

  const dispatch = useDispatch();
  const location = useLocation();

  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef1 = useRef(null);
  const downloadRef2 = useRef(null);

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef1 = useRef(null);
  const graphRef2 = useRef(null);
let SMB;
  useEffect(() => {
    /* ###  Function To Get Inverter Number from URL   ### */

    const pathKeywords = location.pathname.split("/");
     SMB = pathKeywords[5].split("")?.reduce((acc, curr) => {
      if (acc >= "0" && acc <= "9") return acc + curr;
      else return curr;
    }, "");

    /* ###  End of Function To Get Inverter Number from URL   ### */

    setInverter(pathKeywords[4][pathKeywords[4]?.length - 1]);
    setSmb(SMB);
    dispatch(
      InverterSmbYearlyLoss(pathKeywords[4][pathKeywords[4]?.length - 1], SMB)
    );
    dispatch(
      InverterSmbMonthlyLoss(pathKeywords[4][pathKeywords[4]?.length - 1], SMB)
    );
  }, [dispatch, location.pathname]);

  return (
    <>
      {
        loading ? (
          <SpinLoader />
        ) :
        <Grid
          container
          lg={12}
          style={{
            margin: "1vmax 0",
          }}
        >
          <Grid
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            // borderRadius={"14px"}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              margin: "0vmax auto 1vmax",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1vmax",
            }}
          >
            {/*  ###   SMB (String) Average Yearly Loss Graph Container   ### */}

            <Grid
              lg={12}
              style={{
                background: "white",
                // borderRadius: "14px"
                padding: "0.5vmax 1vmax",
                position: "relative",
                width: "100%",
                overflow: "auto",
              }}
            >
              <CSVLink
                data={
                  inverterSmbYearlyLoss?.data?.reduce((acc, curr) => {
                    acc.push({
                      Month: curr.name,
                      LossPercentage: curr.loss,
                    });
                    return acc;
                  }, []) || []
                }
                filename="data.csv"
                className="hidden"
                target="_blank"
                ref={downloadRef1}
              />
              <div
                style={{
                  padding: "0.5rem 1.5rem",
                  position: "sticky",
                  left: "0",
                  right: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  zIndex: "1"
                }}
              >
                <h3 >SMB {smb && smb?.padStart(2,"0")}  (String) Average  Loss</h3>
                <Printer2
                  clickhandler={() => downloadRef1.current.link.click()}
                  jpgDownload={() => {
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "block";
                    exportComponentAsJPEG(graphRef1, { fileName: "graph" });
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "none";
                  }}
                  svgDownload={async () => {
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "block";
                    const svgData = await saveToSvg(
                      graphRef1.current.container
                    );
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "none";
                    saveAs(svgData, "graph.svg");
                  }}
                />
              </div>

              <div className="scbsmb-responsive-container-1">
                <ResponsiveContainer>
                  <ComposedChart
                    data={inverterSmbYearlyLoss?.data || []}
                    margin={{
                      top: 5,
                      right: 5,
                      bottom: 5,
                      left: 5,
                    }}
                    barCategoryGap={"30%"}
                    ref={graphRef1}
                  >
                    <CartesianGrid stroke="grey" strokeWidth={0.3} />
                    <XAxis
                      dataKey="name"
                      fontSize={"0.8rem"}
                      fontWeight={600}
                      tickLine={false}
                      axisLine={false}
                      tickMargin={5}
                    />
                    <YAxis
                      yAxisId="left-axis"
                      fontSize={"0.8rem"}
                      fontWeight={600}
                      width={30}
                      dataKey={() =>
                        Math.ceil(inverterSmbYearlyLoss?.maxValue) === 0
                          ? 0
                          : Math.ceil(inverterSmbYearlyLoss?.maxValue) + 1 || 0
                      }
                      domain={[
                        Math.floor(inverterSmbYearlyLoss?.minValue) - 1 || 0,
                        "dataMax",
                      ]}
                      tickLine={false}
                    />
                    <Tooltip
                      content={
                        <CustomizeTootip
                          active={false}
                          payload={[]}
                          label={""}
                          TooltipValues={["Loss"]}
                        />
                      }
                    />
                    <Legend
                      content={
                        <CustomizeLegend
                          active={false}
                          payload={[]}
                          LegendValues={["Loss"]}
                          data={showEfficiencyYearlyLoss}
                          setData={setShowEfficiencyYearlyLoss}
                        />
                      }
                      styles={{
                        fontSize:"1rem"
                      }}
                    />
                    <Bar
                      hide={showEfficiencyYearlyLoss.loss ? false : true}
                      dataKey="loss"
                      fill={`${colors3[0]}`}
                      yAxisId="left-axis"
                    >
                      <LabelList
                        dataKey="loss"
                        position="top"
                        fontSize={"0.8rem"}
                        fontWeight={600}
                        fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;"
                      />
                    </Bar>
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
          {/*  ###   SMB (String) Average Monthly Loss Graph Container   ### */}

          <Grid
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            // borderRadius={"14px"}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              margin: "0vmax auto 1vmax",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1vmax",
            }}
          >
            <Grid
              lg={12}
              style={{
                background: "white",
                // borderRadius: "14px",
                padding: "0.5vmax 1vmax",
                position: "relative",
                width: "100%",
                overflow: "auto",
              }}
            >
              <CSVLink
                data={inverterSmbMonthlyLoss?.data || []}
                filename="data.csv"
                className="hidden"
                target="_blank"
                ref={downloadRef2}
              />
              <div
                style={{
                  padding: "0.5rem 1.5rem",
                  position: "sticky",
                  left: "0",
                  right: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                <h3>SMB {smb && smb?.padStart(2,"0")} (String) Average Monthly Loss</h3>
                <Printer2
                  clickhandler={() => downloadRef2.current.link.click()}
                  jpgDownload={() => {
                    document.getElementsByClassName(
                      "cpy_right"
                    )[1].style.display = "block";
                    exportComponentAsJPEG(graphRef2, { fileName: "graph" });
                    document.getElementsByClassName(
                      "cpy_right"
                    )[1].style.display = "none";
                  }}
                  svgDownload={async () => {
                    document.getElementsByClassName(
                      "cpy_right"
                    )[1].style.display = "block";
                    const svgData = await saveToSvg(
                      graphRef2.current.container
                    );
                    document.getElementsByClassName(
                      "cpy_right"
                    )[1].style.display = "none";
                    saveAs(svgData, "graph.svg");
                  }}
                />
              </div>
              <div className="scbsmb-responsive-container-2">
                <ResponsiveContainer>
                  <ComposedChart
                    margin={{
                      top: 5,
                      right: 5,
                      bottom: 5,
                      left: 5,
                    }}
                    data={inverterSmbMonthlyLoss?.data || []}
                    ref={graphRef2}
                  >
                    <CartesianGrid stroke="grey" strokeWidth={0.3} />
                    <XAxis
                      fontSize={"0.8rem"}
                      dataKey="name"
                      fontWeight={600}
                      tickLine={false}
                      axisLine={false}
                      tickMargin={5}
                    />
                    <YAxis
                      yAxisId="left-axis"
                      fontSize={"0.8rem"}
                      fontWeight={600}
                      width={30}
                      dataKey={() =>
                        Math.ceil(inverterSmbMonthlyLoss?.maxValue) === 0
                          ? 0
                          : Math.ceil(inverterSmbMonthlyLoss?.maxValue) + 1 || 0
                      }
                      domain={[
                        Math.floor(inverterSmbMonthlyLoss?.minValue) - 1 || 0,
                        "dataMax",
                      ]}
                      tickLine={false}
                    />
                    <Tooltip
                      content={
                        <CustomizeTootip
                          active={false}
                          payload={[]}
                          label={""}
                          TooltipValues={[
                            `SMB${inverter}_${smb}_1`,
                            `SMB${inverter}_${smb}_2`,
                            `SMB${inverter}_${smb}_3`,
                            `SMB${inverter}_${smb}_4`,
                            `SMB${inverter}_${smb}_5`,
                            `SMB${inverter}_${smb}_6`,
                            `SMB${inverter}_${smb}_7`,
                            `SMB${inverter}_${smb}_8`,
                            `SMB${inverter}_${smb}_9`,
                            `SMB${inverter}_${smb}_10`,
                            `SMB${inverter}_${smb}_11`,
                            `SMB${inverter}_${smb}_12`,
                          ]}
                        />
                      }
                    />
                    <Legend
                      content={
                        <CustomizeLegend
                          size={{
                            padding: "1vmax",
                            height: "0.6vmax",
                            fontsize: "1vmax",
                          }}
                          active={false}
                          payload={[]}
                          LegendValues={[
                            `SMB${inverter}_${smb}_1`,
                            `SMB${inverter}_${smb}_2`,
                            `SMB${inverter}_${smb}_3`,
                            `SMB${inverter}_${smb}_4`,
                            `SMB${inverter}_${smb}_5`,
                            `SMB${inverter}_${smb}_6`,
                            `SMB${inverter}_${smb}_7`,
                            `SMB${inverter}_${smb}_8`,
                            `SMB${inverter}_${smb}_9`,
                            `SMB${inverter}_${smb}_10`,
                            `SMB${inverter}_${smb}_11`,
                            `SMB${inverter}_${smb}_12`,
                          ]}
                          data={showEfficiencyMonthlyLoss}
                          setData={setShowEfficiencyMonthlyLoss}
                        styles={{
                          fontSize:"1rem"
                        }}
                        />
                      }
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo1 ? false : true}
                      dataKey={`smb${inverter}_${smb}_1`}
                      fill={getColourPicker(0)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo2 ? false : true}
                      dataKey={`smb${inverter}_${smb}_2`}
                      fill={getColourPicker(1)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo3 ? false : true}
                      dataKey={`smb${inverter}_${smb}_3`}
                      fill={getColourPicker(2)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo4 ? false : true}
                      dataKey={`smb${inverter}_${smb}_4`}
                      fill={getColourPicker(3)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo5 ? false : true}
                      dataKey={`smb${inverter}_${smb}_5`}
                      fill={getColourPicker(4)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo6 ? false : true}
                      dataKey={`smb${inverter}_${smb}_6`}
                      fill={getColourPicker(5)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo7 ? false : true}
                      dataKey={`smb${inverter}_${smb}_7`}
                      fill={getColourPicker(6)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo8 ? false : true}
                      dataKey={`smb${inverter}_${smb}_8`}
                      fill={getColourPicker(7)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo9 ? false : true}
                      dataKey={`smb${inverter}_${smb}_9`}
                      fill={getColourPicker(8)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo10 ? false : true}
                      dataKey={`smb${inverter}_${smb}_10`}
                      fill={getColourPicker(9)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo11 ? false : true}
                      dataKey={`smb${inverter}_${smb}_11`}
                      fill={getColourPicker(10)}
                      yAxisId="left-axis"
                    />
                    <Bar
                      hide={showEfficiencyMonthlyLoss.smbNo12 ? false : true}
                      dataKey={`smb${inverter}_${smb}_12`}
                      fill={getColourPicker(11)}
                      yAxisId="left-axis"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default SCBSMB;
