// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ##### -> Components ->  PresCripModel ##### */

@media screen and (max-width:900px) {
    .prescriptive-container{
        height: 120% !important;
    }
    
    .prescriptive-container-item-1{
        height: 50% !important;
    }
    .prescriptive-container-item-2{
        height: 50% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/modules/countries/components/prescriptiveModel/prescritive.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAEhD;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["/* ##### -> Components ->  PresCripModel ##### */\n\n@media screen and (max-width:900px) {\n    .prescriptive-container{\n        height: 120% !important;\n    }\n    \n    .prescriptive-container-item-1{\n        height: 50% !important;\n    }\n    .prescriptive-container-item-2{\n        height: 50% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
