// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* #####  Code for ButtonsComp  ##### */

.button-primary {
  background-color: #ed7d31;
  color: black;
  font-weight: bolder;
  margin: 5px;
  height: 7vh;
  width: 7vw;
}
.button-secondary {
  background-color: white;
  color: black;
  font-weight: bolder;
  margin: 5px;
  height: 7vh;
  width: 7vw;
}
.button-btn{
  font-size: 1.7vmin !important;
 

}
@media screen and (max-width:950px) {
  .button-btn{
    font-size: 1.5vmin !important;
  }
}
@media screen and (max-width:500px) {
  .button-btn{
    font-size: 2vmin !important;
  }
}


`, "",{"version":3,"sources":["webpack://./src/components/ButtonsComp.css"],"names":[],"mappings":";AACA,uCAAuC;;AAEvC;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,UAAU;AACZ;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,UAAU;AACZ;AACA;EACE,6BAA6B;;;AAG/B;AACA;EACE;IACE,6BAA6B;EAC/B;AACF;AACA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["\n/* #####  Code for ButtonsComp  ##### */\n\n.button-primary {\n  background-color: #ed7d31;\n  color: black;\n  font-weight: bolder;\n  margin: 5px;\n  height: 7vh;\n  width: 7vw;\n}\n.button-secondary {\n  background-color: white;\n  color: black;\n  font-weight: bolder;\n  margin: 5px;\n  height: 7vh;\n  width: 7vw;\n}\n.button-btn{\n  font-size: 1.7vmin !important;\n \n\n}\n@media screen and (max-width:950px) {\n  .button-btn{\n    font-size: 1.5vmin !important;\n  }\n}\n@media screen and (max-width:500px) {\n  .button-btn{\n    font-size: 2vmin !important;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
