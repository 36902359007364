import React, { useRef, useState } from "react";
import Table from "../../../../components/Table";
import { Grid } from "@mui/material";
import Printer from "../../../../components/Printer";
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { CSVLink } from "react-csv";
import { saveAs } from 'file-saver'
import './prescritive.css'
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";

const data = [
  {
    name: "Module",
    Loss: 5.25,
    // fill: "#FF0000"
    fill: "#de425b"
  },
  {
    name: "Soiling",
    Loss: 3.5,
    // fill:"rgb(227, 94, 27)"
    fill: "#e18745"
  },
  {
    name: "Shadow",
    Loss: 1.3,
    // fill: "#FFA500"
    fill: "#dac767"
  },
  {
    name: "Resistance",
    Loss: 1,
    // fill:"rgb(223, 200, 51)"
    fill: "#78ab63"
  },
  {
    name: "Overheating",
    Loss: 1,
    // fill: "rgb(27, 84, 227)"
    // fill: "rgb(24, 134, 245)"
    // fill:"rgb(252, 237, 107)" 
    // fill:"rgb(94, 216, 253)"
    fill: "#488f31"
  },
];

const PresCripModel = () => {
  const [dataSet, setDataSet] = useState(data);
  const [showCheckBox, setShowCheckBox] = useState({
    Module: true,
    Soiling: true,
    Shadow: true,
    Resistance: true,
    Overheating: true,
  });

  const [showLoss, setShowLoss] = useState({
    loss: true,
    all: true
  })
  /* ###   Reference to download graph data in CSV format   ### */

  const lossDownloadRef = useRef(null)

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const downloadRef1 = useRef(null)

  /* ###   Function To Visible Selected Graph Values   ### */

  const onChangeCheckBox = ({ name }) => {
    try {
      const newData1 = data?.filter((item) => {
        if (item?.name !== name) {
          if (showCheckBox[item?.name]) return item;
          return null;
        } else {
          if (!showCheckBox[item?.name]) return item;
          return null
        }
      });
      const checked = { ...showCheckBox };
      checked[name] = !checked[name];
      setShowCheckBox(checked);
      setDataSet(newData1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          padding: "1vmax 0",
          flex:1
        }}
      >
        <Grid
          className="prescriptive-container"

          sx={{ boxShadow: 2 }}
          container
          xl={11.8}
          lg={11.8}
          md={11.8}
          sm={11.8}
          xs={11.8}
          style={{
            height:"inherit",
            margin: "0 auto",
            boxSizing: "border-box",
            borderWidth: "3px",
            padding: "1vmax",
            display: "flex",
            justifyContent: "center",
            gap: "1vmax",

            background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
          }}
        >
          <Grid
            className="prescriptive-container-item-1"
            item
            lg={5.9}
            md={5.9}
            sm={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              background: "white",
            }}
            sx={{height:"100%"}}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Table onChange={onChangeCheckBox} />
            </div>
          </Grid>
          <Grid
            item
            lg={5.9}
            md={5.9}
            sm={12}
            style={{
              background: "white",
              height: "100%",
            }}
            className="prescriptive-container-item-2"
          >
            <div style={{ height: "100%", background: "white", position: "relative" }}>
              <CSVLink
                data={dataSet?.reduce((acc, curr) => {
                  acc.push({
                    name: curr.name,
                    LossPercentage: curr.Loss
                  })
                  return acc;
                }, []) || []}
                filename='data.csv'
                className='hidden'
                ref={lossDownloadRef}
                target='_blank'
              />

              <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                <Printer clickhandler={() => lossDownloadRef.current.link.click()}
                  jpgDownload={() => {
                    document.getElementsByClassName("cpy_right")[0].style.display = "block";
                    exportComponentAsJPEG(downloadRef1, { fileName: "graph" })
                    document.getElementsByClassName("cpy_right")[0].style.display = "none";
                  }}
                  svgDownload={async () => {
                    document.getElementsByClassName("cpy_right")[0].style.display = "block";
                    const svgData = await saveToSvg(downloadRef1.current.container)
                    document.getElementsByClassName("cpy_right")[0].style.display = "none";
                    saveAs(svgData, 'graph.svg')
                  }}
                />
              </div>
              <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", boxSizing: "border-box" }}>
                <h3 style={{ textAlign: "center", padding: "1vmax 0", fontSize: "2vmin" }}>
                  Different Type Losses
                </h3>
                <div style={{ width: "100%", height: "100%" }}>
                  <ResponsiveContainer>
                    <ComposedChart data={dataSet || []} ref={downloadRef1}
                      margin={{
                        right: 10,
                        top: 10,
                        bottom: 10,
                        left: 10
                      }}
                      barCategoryGap={"25%"}
                    >
                      <XAxis fontSize={"2vmin"} interval={0} dataKey="name" tickLine={false} axisLine={false} tickMargin={5}
                      />
                      <CartesianGrid stroke="grey" strokeWidth={0.2} />
                      <YAxis type="number"
                        fontSize={"2.5vmin"}
                        dataKey={() => Math.ceil(dataSet?.reduce((acc, curr, index) => curr.Loss > acc ? curr.Loss : acc, -Infinity)) + 1}
                        domain={[Math.floor(dataSet?.reduce((acc, curr, index) => curr.Loss < acc ? curr.Loss : acc, Infinity)) - 1, 'dataMax']}
                        tickLine={false} tickMargin={8}
                      />
                      <Legend content={<CustomizeLegend active={false} payload={[]} label={""} LegendValues={["Loss"]} data={showLoss} setData={setShowLoss} />}
                      />
                      <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Loss"]} />} contentStyle={{ fontSize: "0.7rem" }} />
                      {
                        <Bar hide={showLoss.loss ? false : true} dataKey="Loss" fill={colors3[0]} onTransitionEnd={"1s all"} >
                          <LabelList dataKey="Loss" position="top" fontSize={"1.8vmax"} fontWeight={600} fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;" />
                        </Bar>
                      }
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PresCripModel;
