// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ##### Code SmallCard Component In Project Overview Component ##### */

.small-card-style {

  background: linear-gradient(to right, #bde3fd, #73c3fc);

  border-radius: 14px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5vmax 0.5vmax;
  min-height: 35vh;
  margin: 0vmax 0;
}
.view-button{
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.5rem 1.2rem;
  border-radius: 4px;
  background-color: rgb(23, 167, 210);
  cursor: pointer;
  transition: .1s ease-in-out;
  font-family: sans-serif;
  font-size: 2vmin;
}
.view-button:hover{
  background-color: rgb(44, 159, 194);
}

@media screen and (max-width:500px) {
  .small-card-style{
    min-height: 30vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SmallCard.css"],"names":[],"mappings":"AAAA,uEAAuE;;AAEvE;;EAEE,uDAAuD;;EAEvD,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;EACf,2BAA2B;EAC3B,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["/* ##### Code SmallCard Component In Project Overview Component ##### */\n\n.small-card-style {\n\n  background: linear-gradient(to right, #bde3fd, #73c3fc);\n\n  border-radius: 14px;\n  width: 90%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  padding: 1.5vmax 0.5vmax;\n  min-height: 35vh;\n  margin: 0vmax 0;\n}\n.view-button{\n  text-transform: uppercase;\n  font-weight: 700;\n  padding: 0.5rem 1.2rem;\n  border-radius: 4px;\n  background-color: rgb(23, 167, 210);\n  cursor: pointer;\n  transition: .1s ease-in-out;\n  font-family: sans-serif;\n  font-size: 2vmin;\n}\n.view-button:hover{\n  background-color: rgb(44, 159, 194);\n}\n\n@media screen and (max-width:500px) {\n  .small-card-style{\n    min-height: 30vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
