// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* ### Code for AdminDashboard component ### */ 

.user-table{
    text-align: center;
    font-size: 1.2vmax;
    /* background:linear-gradient(to right,rgb(138, 181, 207),rgb(233, 228, 228)); */
    /* background:rgb(138, 181, 207) !important; */
    background: rgb(3, 99, 125) !important;

    padding: 0.5vmax 1vmax;
}
.user-table>tr {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.user-table>tr>th{
    color: white;
    padding: 1.5vmin 1vmin;
}

.user-action-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vmax;
}
.user-action-buttons>span{
    padding: 0.5vmax 1vmax;
    border-radius: 4px;
    margin: 0.5vmax 0;
    font-size: 2vmin;
    cursor: pointer;
    font-weight: 650;
}
.user-action-buttons>:first-child{
    background-color: rgb(255, 123, 0);
    color: rgb(19, 17, 17);
}

.user-action-buttons>:last-child{
    background-color: rgb(67, 65, 64);
    color: white;
}
.user-action-buttons>:first-child:hover{
    background-color: rgb(253, 137, 27);


}
.user-action-buttons>:last-child:hover{
    background-color: rgb(38, 37, 36);

}
`, "",{"version":3,"sources":["webpack://./src/modules/countries/components/AdminDashboard.css"],"names":[],"mappings":";AACA,8CAA8C;;AAE9C;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gFAAgF;IAChF,8CAA8C;IAC9C,sCAAsC;;IAEtC,sBAAsB;AAC1B;AACA;IACI,kCAAkC;IAClC,qCAAqC;AACzC;AACA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,kCAAkC;IAClC,sBAAsB;AAC1B;;AAEA;IACI,iCAAiC;IACjC,YAAY;AAChB;AACA;IACI,mCAAmC;;;AAGvC;AACA;IACI,iCAAiC;;AAErC","sourcesContent":["\n/* ### Code for AdminDashboard component ### */ \n\n.user-table{\n    text-align: center;\n    font-size: 1.2vmax;\n    /* background:linear-gradient(to right,rgb(138, 181, 207),rgb(233, 228, 228)); */\n    /* background:rgb(138, 181, 207) !important; */\n    background: rgb(3, 99, 125) !important;\n\n    padding: 0.5vmax 1vmax;\n}\n.user-table>tr {\n    border-top: 10px solid transparent;\n    border-bottom: 10px solid transparent;\n}\n.user-table>tr>th{\n    color: white;\n    padding: 1.5vmin 1vmin;\n}\n\n.user-action-buttons{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 1vmax;\n}\n.user-action-buttons>span{\n    padding: 0.5vmax 1vmax;\n    border-radius: 4px;\n    margin: 0.5vmax 0;\n    font-size: 2vmin;\n    cursor: pointer;\n    font-weight: 650;\n}\n.user-action-buttons>:first-child{\n    background-color: rgb(255, 123, 0);\n    color: rgb(19, 17, 17);\n}\n\n.user-action-buttons>:last-child{\n    background-color: rgb(67, 65, 64);\n    color: white;\n}\n.user-action-buttons>:first-child:hover{\n    background-color: rgb(253, 137, 27);\n\n\n}\n.user-action-buttons>:last-child:hover{\n    background-color: rgb(38, 37, 36);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
