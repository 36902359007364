import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux"
import { All_Inverter_Efficiency_Monthly, Inverter_Efficiency } from "../../../../actions/inverterActions";
import SpinLoader from "../../../../components/SpinLoader";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import { colors3 } from "../../../../colors/color";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Printer from "../../../../components/Printer";
import { CSVLink } from "react-csv";
import { saveAs } from 'file-saver'
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";


const InverterEfficiency = () => {
  const dispatch = useDispatch();
  const { efficiencies, loading } = useSelector(state => state.efficiency)
  const { monthly_inverter_efficiency, loading2 } = useSelector(state => state.monthly_inverter_efficiency)
  const [InverterData, setInverterData] = useState(null)
  const [InverterMonthlyData, setInverterMonthlyData] = useState(null)

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showEfficiency, setShowEfficiency] = useState({ efficiency: true, all: true })
  const [showEfficiencyMonthly, setShowEfficiencyMonthly] = useState({
    inverter1: true,
    inverter2: true,
    inverter3: true,
    inverter4: true,
    all: true
  });

  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef1 = useRef(null)
  const downloadRef2 = useRef(null)

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef1 = useRef(null)
  const graphRef2 = useRef(null)

  useMemo(() => {
    setInverterData(efficiencies)
    setInverterMonthlyData(monthly_inverter_efficiency)
  }, [efficiencies, monthly_inverter_efficiency])



  useEffect(() => {
    dispatch(Inverter_Efficiency())
    dispatch(All_Inverter_Efficiency_Monthly())
  }, [dispatch])


  return (
    <>
      {
        loading || loading2 ? <SpinLoader /> :
          <>
            {InverterData && InverterMonthlyData &&
              <Grid
                container
                style={{
                  minHeight: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  margin: "1vmax 0",
                 
                }}
              >
                <Grid container
                  xl={11.8}
                  lg={11.8}
                  md={11.8}
                  sm={11.8}
                  xs={11.8}
                  // borderRadius={"14px"}
                  style={{
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1vmax",
                    padding: "1vmax"
                  }}>

                  {/*  ###   Monthly Inverter Efficiency Graph Container   ### */}

                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={12}
                    sm={12}
                    sx={12}
                    // border={3}
                    // borderColor={"#ed7d31"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    position={"relative"}
                    bgcolor={"white"}
                    // borderRadius={"14px"}
                    padding={"1vmax 0"}

                  >
                    <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                      <Printer clickhandler={() => downloadRef1.current.link.click()}
                        jpgDownload={() => {
                          document.getElementsByClassName("cpy_right")[0].style.display = "block";
                          exportComponentAsJPEG(graphRef1, { fileName: "graph" })
                          document.getElementsByClassName("cpy_right")[0].style.display = "none";
                        }}
                        svgDownload={async () => {
                          document.getElementsByClassName("cpy_right")[0].style.display = "block";
                          const svgData = await saveToSvg(graphRef1.current.container)
                          document.getElementsByClassName("cpy_right")[0].style.display = "none";
                          saveAs(svgData, 'graph.svg')
                        }}
                      />
                    </div>
                    <CSVLink
                      data={efficiencies?.reduce((acc, curr) => {
                        acc.push({
                          Month: curr.name,
                          Efficiency: curr.Efficiency
                        })
                        return acc;
                      }, []) || []}
                      filename='data.csv'
                      className='hidden'
                      target='_blank'
                      ref={downloadRef1}
                    />
                    <h3 style={{ textAlign: "center", fontSize: "2.5vmin", paddingBottom: "1vmin" }}>
                      Efficiency
                    </h3>
                    <div style={{ height: "73vh", width: "100%" }}>
                      <ResponsiveContainer>
                        <ComposedChart
                          data={efficiencies || []}
                          margin={{
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 5,
                          }}
                          barCategoryGap={"30%"}
                          ref={graphRef1}
                        >
                          <CartesianGrid stroke="grey" strokeWidth={0.3} />
                          <XAxis
                            dataKey="name"
                            fontSize={"1.8vmin"} fontWeight={600}
                            tickLine={false} axisLine={false} tickMargin={8}
                          />
                          <YAxis yAxisId="left-axis"
                            fontSize={"2vmin"}
                            //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}} 
                            // label={<AxisLabel axisType='yAxis' x={-17} y={-7}>Energy</AxisLabel>}
                            dataKey={() => Math.ceil(efficiencies?.reduce((acc, curr, index) => Math.max(curr.Efficiency, acc), -Infinity))}
                            domain={[Math.floor(efficiencies?.reduce((acc, curr, index) => Math.min(curr.Efficiency, acc), Infinity)), 'dataMax']}
                            tickLine={false} tickMargin={8}
                          />
                          <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Efficiency"]} />} />
                          <Legend
                            content={<CustomizeLegend active={false} payload={[]} LegendValues={["Efficiency"]} data={showEfficiency} setData={setShowEfficiency} />}
                          />
                          <Bar
                            hide={showEfficiency.efficiency ? false : true}
                            dataKey="Efficiency"
                            fill={`${colors3[0]}`}
                            yAxisId="left-axis"
                          >
                            <LabelList dataKey="Efficiency" position="top" fontSize={"0.9rem"} fontWeight={600} fontFamily="Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;" />
                          </Bar>
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </Grid>

                  {/*  ###   Yearly Inverter Efficiency Graph Container   ### */}

                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={12}
                    sm={12}
                    sx={12}
                    // border={3}
                    // borderColor={"#ed7d31"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    position={"relative"}
                    bgcolor={"white"}
                    // borderRadius={"14px"}
                    padding={"1vmax 0"}
                  >
                    <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                      <Printer clickhandler={() => downloadRef2.current.link.click()}
                        jpgDownload={() => {
                          document.getElementsByClassName("cpy_right")[1].style.display = "block";
                          exportComponentAsJPEG(graphRef2, { fileName: "graph" })
                          document.getElementsByClassName("cpy_right")[1].style.display = "none";
                        }}
                        svgDownload={async () => {
                          document.getElementsByClassName("cpy_right")[1].style.display = "block";
                          const svgData = await saveToSvg(graphRef2.current.container)
                          document.getElementsByClassName("cpy_right")[1].style.display = "none";

                          saveAs(svgData, 'graph.svg')
                        }}
                      />
                    </div>
                    <CSVLink
                      data={monthly_inverter_efficiency?.reduce((acc, curr) => {
                        acc.push({
                          Month: curr.name,
                          Inveter1: curr.Inverter1,
                          Inveter2: curr.Inverter2,
                          Inveter3: curr.Inverter3,
                          Inveter4: curr.Inverter4
                        })
                        return acc;
                      }, []) || []}
                      filename='data.csv'
                      className='hidden'
                      target='_blank'
                      ref={downloadRef2}
                    />
                    <h3 style={{ textAlign: "center", fontSize: "2.5vmin", paddingBottom: "1vmin" }}>
                      Inverter Efficiency Monthly
                    </h3>
                    <div style={{ height: "73vh", width: "100%" }}>
                      <ResponsiveContainer>
                        <ComposedChart
                          data={monthly_inverter_efficiency || []}
                          margin={{
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 5,
                          }}
                          ref={graphRef2}
                        >
                          <CartesianGrid stroke="grey" strokeWidth={0.3} />
                          <XAxis
                            dataKey="name"
                            fontSize={"1.8vmin"} fontWeight={600}
                            tickLine={false} axisLine={false} tickMargin={5}
                          />
                          <YAxis yAxisId="left-axis"
                            fontSize={"2vmin"}

                            //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}} 
                            // label={<AxisLabel axisType='yAxis' x={-17} y={-7}>Energy</AxisLabel>}
                            dataKey={() => 98} domain={[96, 'dataKey']} tickCount={11}
                            tickLine={false} tickMargin={8}
                          // tickCount={11}
                          // dataKey={() => Math.ceil(monthly_inverter_efficiency?.reduce((acc, curr, index) => Math.max(curr.Inverter1, curr.Inverter2,curr.Inverter3,curr.Inverter4, acc), -Infinity))}
                          // domain={[Math.floor(monthly_inverter_efficiency?.reduce((acc, curr, index) => Math.min(curr.Inverter1,curr.Inverter2,curr.Inverter3,curr.inverter4, acc), Infinity)), 'dataMax']}
                          />
                          <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Inverter 1", "Inverter 2", "Inverter 3", "Inverter 4"]} />} />
                          <Legend
                            content={<CustomizeLegend active={false} payload={[]} LegendValues={["Inverter 1", "Inverter 2", "Inverter 3", "Inverter 4"]} data={showEfficiencyMonthly} setData={setShowEfficiencyMonthly} />}
                          />
                          <Bar
                            hide={showEfficiencyMonthly.inverter1 ? false : true}
                            dataKey="Inverter1"
                            fill={`${colors3[0]}`}
                            yAxisId="left-axis"
                          />
                          <Bar
                            hide={showEfficiencyMonthly.inverter2 ? false : true}
                            dataKey="Inverter2"
                            fill={`${colors3[2]}`}
                            yAxisId="left-axis"
                          />
                          <Bar
                            hide={showEfficiencyMonthly.inverter3 ? false : true}
                            dataKey="Inverter3"
                            fill={`${colors3[3]}`}
                            yAxisId="left-axis"
                          />
                          <Bar
                            hide={showEfficiencyMonthly.inverter4 ? false : true}
                            dataKey="Inverter4"
                            fill={`${colors3[1]}`}
                            yAxisId="left-axis"
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            }
          </>

      }
    </>
  );
};
export default InverterEfficiency;


