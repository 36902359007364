import React, { useEffect, useRef, useState } from "react";
import "../CountryDefault.css";
import "./ProjOverView.css";
import { Grid } from "@mui/material";
import ProjPic from "../../../../fonts and images/projectOverViewPic.jpg";
import SmallCard from "../../../../components/SmallCard";
import GeneralDetails from "./GeneralDetails";
import InverterDetails from "./InverterDetails";
import SmbDetails from "./SmbDetails";
import { useDispatch, useSelector } from "react-redux";
import { powerPlantDetail } from "../../../../actions/inverterActions";
import SpinLoader from "../../../../components/SpinLoader";
import AsBuiltDetails from "./AsBuiltDetails";

const ProjOverView = () => {
  const [showDetails, setShowDetails] = useState({
    showGeneralDetails: false,
    showInverterDetails: false,
    showAsbuiltDetails: false,
    showSMB: false,
  });
  const { plantDetail, loading } = useSelector(state => state.plantDetail)

  const refReset = useRef(null)
  const refForGeneralDetails = useRef(null);
  const refInverterDetails = useRef(null);
  const refsmb = useRef(null)
  const refAsbuiltDiagram = useRef(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if (showDetails.showGeneralDetails) {
      refForGeneralDetails.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (showDetails.showInverterDetails) {
      refInverterDetails.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (showDetails.showSMB) {
      refsmb.current?.scrollIntoView({ behavior: 'smooth' })

    }
    if (showDetails.showAsbuiltDetails) {
      refAsbuiltDiagram.current?.scrollIntoView({ behavior: 'smooth' })

    }
    if (!plantDetail) {
      dispatch(powerPlantDetail())
    }
  }, [showDetails, dispatch,plantDetail])


  return (

    < >
      {loading ?
        <SpinLoader /> :
        <div ref={refReset}  >
          <Grid container textAlign={"center"} >

            {/* ###  Project Overview Image Container  ### */}

            <Grid item
              lg={12}
              md={12}
              xs={12}
              xl={12}
              style={{ height: "45vh" }}
            >
              <img
                src={ProjPic}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="Project-Overview"
              />
            </Grid>
            <Grid
              item
              lg={11.8}
              md={11.8}
              xl={11.8}
              xs={11.8}

              style={{
                margin: "1vmax auto",
                padding: "0.8vmax 0.5vmax",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >

            {/* ###  Card Container  ### */}

              <Grid
                container
                spacing={2}
                lg={12}
                style={{
                  boxSizing: "border-box",
                  borderRadius: "14px",
                  padding: "1vmax",
                  
                }}
                justifyContent={"center"}
              >
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={10}

                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    heading="General Details"
                    body={[
                      `Location: ${plantDetail?.data2.plantName}`,
                      `Capacity DC [MWp]: ${plantDetail?.data2.dcCapacity}`,
                      `Capacity AC [MW]: ${plantDetail?.data2.acCapacity}`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showInverterDetails: false,
                        showSMB: false,
                        showAsbuiltDetails: false,
                        showGeneralDetails: !showDetails?.showGeneralDetails,
                      });
                    }}
                    showExpanded={showDetails?.showGeneralDetails}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    heading="As-built Details"
                    body={[
                      `Datasheet: Modules, Inverter, Transformer`,
                      `Cable: Cable Details`,
                      `SLD: DC SLD and AC SLD`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showGeneralDetails: false,
                        showInverterDetails: false,
                        showSMB: false,
                        showAsbuiltDetails: !showDetails?.showAsbuiltDetails,
                      });
                    }}
                    showExpanded={showDetails?.showAsbuiltDetails}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}

                  xs={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    heading="Inverters"
                    body={[
                      `Number of Inverters: ${plantDetail?.data2.numberOfInverter}`,
                      `Specifications: ${plantDetail?.data2.inverterDetails}`,
                      `Inverter Type: ${plantDetail?.data2.inverterType
                      }`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showGeneralDetails: false,
                        showSMB: false,
                        showAsbuiltDetails: false,
                        showInverterDetails: !showDetails?.showInverterDetails,
                      });
                    }}
                    showExpanded={showDetails?.showInverterDetails}
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SmallCard
                    bgColor="#ed7d31"
                    heading="SMB & String"
                    body={[
                      `Number of SMB: ${plantDetail?.data2.smbNo}`,
                      `Number of string: ${plantDetail?.data2.stringNo}`,
                      `String Type: ${plantDetail?.data2.typeOfString}`
                    ]}
                    data={plantDetail?.data2}
                    onClick={() => {
                      setShowDetails({
                        ...showDetails,
                        showGeneralDetails: false,
                        showInverterDetails: false,
                        showAsbuiltDetails: false,
                        showSMB: !showDetails?.showSMB,
                      });
                    }}
                    showExpanded={showDetails?.showSMB}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ###  GeneralDetails Container  ### */}

            {showDetails?.showGeneralDetails && (
              <Grid
                item
                lg={11.8}
                md={11.8}
                xl={11.8}
                xs={11.8}
                style={{
                  margin: "1rem auto",
                  padding: "0.9vmax 1.1vmax",
                  background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                }}
              >
                <Grid container lg={12}
                  style={{
                    backgroundColor: "white",
                    // borderRadius: "14px",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    ref={refForGeneralDetails}
                  >
                    <GeneralDetails />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* ###  AsbuiltDetails Container  ### */}

            {showDetails?.showAsbuiltDetails &&
              (
                <Grid item
                  lg={11.8}
                  md={11.8}
                  xl={11.8}
                  xs={11.8}
                  style={{
                    margin: "1rem auto",
                    padding: "0.9vmax 1.1vmax",
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                >
                  <Grid container lg={12}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      ref={refAsbuiltDiagram}
                    >
                      <AsBuiltDetails />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }

            {/* ###  InverterDetails Container  ### */}

            {showDetails?.showInverterDetails &&
              (
                <Grid
                  item
                  lg={11.8}
                  md={11.8}
                  xl={11.8}
                  xs={11.8}

                  style={{
                    margin: "1rem auto",
                    padding: "0.9vmax 1.1vmax",
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                >
                  <Grid container lg={12}
                    style={{
                      backgroundColor: "white",
                    }}
                  > <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    style={{
                      margin: "auto",
                    }}
                    ref={refInverterDetails}
                  >
                      <InverterDetails />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
            {/* ###  String/SMB Detail Container  ### */}

            {showDetails?.showSMB &&
              (
                <Grid item
                  lg={11.8}
                  md={11.8}
                  xl={11.8}
                  xs={11.8}

                  style={{
                    margin: "1rem auto",
                    padding: "0.9vmax 1.1vmax",
                    background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  }}
                >

                  <Grid container lg={12}
                    style={{
                      backgroundColor: "white",
                    }}
                  ><Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    ref={refsmb}
                  >
                      <SmbDetails />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </div>
      }
    </>


  );
};

export default ProjOverView;
