import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

/* ###  Inverter Details Component  ### */ 

const InverterDetails = () => {
  const { plantDetail } = useSelector(state => state.plantDetail)
  return (
    <div style={{ width: "100%" }}>
      <h2 style={{ textAlign: "center", padding: "2vmin 0" }}>Inverter Details</h2>
      <TableContainer sx={{ padding: "0vmax 2vmin", width: "100%" ,whiteSpace:"nowrap"}} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#edeaea" }}>
              <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                Inverter No.
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                Inverter I/P Current (A)
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                Power DC (KW)
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
                Power AC (KW)
              </TableCell>
              <TableCell align="center" style={{ fontWeight: "bolder", fontSize: "2vmin" }}>
               DC:AC Overloading
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {plantDetail?.inverter_details?.map((value, index, arr) => (
              <TableRow key={index}>
                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.inverterNo}</TableCell>
                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.invInputCurrent}</TableCell>
                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.dcPower}</TableCell>
                <TableCell align="center" style={{ fontSize: "2vmin" }}>{value.acPower}</TableCell>
                <TableCell align="center" style={{ fontSize: "2vmin" }}>{(value["dcPower"]/value["acPower"]).toFixed(2)}:1</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InverterDetails;
