import React, { useState } from "react";
import { Checkbox, Grid, InputLabel } from "@mui/material";
import trasmission_tower from '../../../../fonts and images/transmission.png'
import amrMeter from '../../../../fonts and images/amr_meter.jpeg'
import transformer from '../../../../fonts and images/transformer.jpg'
import inverter2 from '../../../../fonts and images/inverter.png'
import scada from '../../../../fonts and images/scada.jpg'
import './LossFlow.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WbSunnyIcon from '@mui/icons-material/WbSunny';



const LossFlow = () => {

    const [showCheckBox, setShowCheckBox] = useState({
        Module: false,
        Soiling: false,
        Shadow: false,
        Resistance: false,
        Overheating: false,
    });


    return (
        <Grid container sx={{flex:1}}>
            <Grid
                container
                lg={12}
                className="loss-flow-container"
            >
                <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", }}>
                    <div style={{
                        width: "100%",
                        margin: "auto",
                        overflow: "auto",
                        height: "90%",
                        padding: "1vmin 0  5vmin 0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "17rem",
                    }}>
                        <div className="animate-block-1" >
                            <div className="transmission-tower">
                                <img src={trasmission_tower} alt="Transmission Tower" title="Transmission Tower" />
                                <span>Transmission Tower</span>
                            </div>
                            <ArrowBackIcon sx={{ fontSize: "1.9rem" }} className="direction-arrow" />
                            <div className="amrmeter">
                                <img src={amrMeter} alt="Ammeter" title="Ammeter" />
                                <span >Ammeter</span>
                            </div>
                            <ArrowBackIcon sx={{ fontSize: "1.9rem" }} className="direction-arrow" />
                            <div className="transformer">
                                <img src={transformer} alt="Transformer" title="Transformer" />
                                <span>Transformer</span>
                            </div>
                            <ArrowBackIcon sx={{ fontSize: "1.9rem",visibility:"hidden" }} className="direction-arrow" />
                            <div className="inverter">
                                <img src={inverter2} alt="Inverter" title="Inverter" />
                                <span>Inverter</span>
                                <div style={{ background: showCheckBox.Overheating ? "blue" : "red" }} >
                                    <div style={{ background: showCheckBox.Overheating ? " blue" : "red" }} >

                                        <div style={{ background: showCheckBox.Overheating ? "blue" : "red" }} >
                                            <div>
                                                SMB Box
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{position:"absolute",top:"2%",right:"93%",width:"105%",height:"0.15rem",background:"red"}} ></div>
                                <div style={{position:"absolute",top:"7%",right:"93%",width:"105%",height:"0.15rem",background:"yellow"}} ></div>
                                <div style={{position:"absolute",top:"11.5%",right:"93%",width:"105%",height:"0.15rem",background:"blue"}} ></div>
                                

                                <div style={{ background: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }}>
                                    <div style={{ background: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }}>
                                        <div style={{ background: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <ArrowBackIcon className="direction-arrow" /> */}
                            <span></span>
                            <div className="scada">
                                <img alt="SCADA" src={scada} title="SCADA" />
                                <span>SCADA</span>
                            </div>
                        </div>
                        <div className="animate-block-2" >
                            <div >
                                <WbSunnyIcon sx={{
                                    color: "#FDB813", fontSize: "5rem"
                                }} />
                                <span>Sun</span>
                            </div>
                            <div className="solar-box-1">
                                <div style={{ position: "absolute", top: "-40%", left: "20%", height: "3rem", borderLeft: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="left-border-connnector">
                                    <div style={{ position: "absolute", top: "0", left: "0", width: "4rem", borderTop: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="top-border-connnector">
                                        <i className="fa-solid fa-y y-connector"
                                            style={{
                                                position: "absolute",
                                                fontSize: "3rem",
                                                fontWeight: "bolder",
                                                bottom: "-0.15rem",
                                                right: "-47%",
                                                rotate: "180deg",
                                                color: showCheckBox.Resistance ? "red" : "black"
                                            }}
                                        >
                                            <div style={{ position: "absolute", top: "95%", left: "40%", height: "2.7rem", borderLeft: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="left-border-connnector">
                                            </div>
                                        </i>
                                    </div>
                                </div>

                                <div className="solar-string-box-1">
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-1"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-2"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-3">solar panel </div>
                                </div>
                                <div className="solar-string-box-2">
                                    <div style={{ position: "absolute", top: "-40%", left: "50%", height: "3rem", borderLeft: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="left-border-connnector">
                                        <div style={{ position: "absolute", top: "0", right: "0", width: "4rem", borderTop: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="top-border-connnector">
                                        </div>
                                    </div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-1"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-2"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-3">solar panel</div>
                                </div>
                            </div>
                            <div className="solar-box-2">
                                <div style={{ position: "absolute", top: "-40%", left: "20%", height: "3rem", borderLeft: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="left-border-connnector">
                                    <div style={{ position: "absolute", top: "0", left: "0", width: "4rem", borderTop: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="top-border-connnector">
                                        <i className="fa-solid fa-y y-connector"
                                            style={{
                                                position: "absolute",
                                                fontSize: "3rem",
                                                fontWeight: "bolder",
                                                bottom: "-0.15rem",
                                                right: "-47%",
                                                rotate: "180deg",
                                                color: showCheckBox.Resistance ? "red" : "black"
                                            }}
                                        >
                                            <div style={{ position: "absolute", top: "95%", left: "40%", height: "2.7rem", borderLeft: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="left-border-connnector">

                                            </div>


                                        </i>

                                    </div>

                                </div>

                                <div className="solar-string-box-3">
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-1"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-2"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-3">solar panel</div>
                                </div>
                                <div className="solar-string-box-4">
                                    <div style={{ position: "absolute", top: "-40%", left: "45%", height: "3rem", borderLeft: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="left-border-connnector">
                                        <div style={{ position: "absolute", top: "0", right: "0", width: "3.6rem", borderTop: showCheckBox.Resistance ? "7px dashed red" : "7px dashed black" }} className="top-border-connnector"></div>

                                    </div>

                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-1"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-2"></div>
                                    <div
                                        style={{ border: showCheckBox.Module || showCheckBox.Shadow || showCheckBox.Soiling ? "5px solid red" : "2px solid rgb(67, 176, 239)" }}
                                        className="solar-panel-3">solar panel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span style={{ fontSize: "3vmin", fontWeight: "600" }}>Loss Flow Table</span>
                    <table
                        className="loss_flow_table"
                        style={{
                            height: "98%",
                            border: "3px solid black",
                        }}
                    >
                        <tr style={{ backgroundColor: "#edeafb", padding: "0.5vmax 0" }}>
                            <th></th>
                            <th>Losses</th>
                            <th>Description</th>
                            <th>Loss (%)</th>
                        </tr>
                        <tr style={{ padding: "1vmax 0" }}>
                            <td>
                                <Checkbox
                                    id="module"
                                    checked={showCheckBox.Module}
                                    color="success"
                                    onChange={() => setShowCheckBox({ ...showCheckBox, Module: !showCheckBox.Module })}
                                />
                            </td>

                            <td style={{ textAlign: "center" }}><InputLabel style={{ color: "black", fontSize: "2vmin" }} htmlFor="module" >Module</InputLabel> </td>

                            <td style={{ textAlign: "center" }}>
                                There is a multiple of module defects have been identified with bypass
                                diode dominating with 5.25% impact on underperformance
                            </td>
                            <td style={{ textAlign: "center" }}>5.25 %</td>
                        </tr>

                        <tr>
                            <td>
                                <Checkbox

                                    checked={showCheckBox.Soiling}
                                    id="soiling"
                                    color="success"
                                    onChange={() => setShowCheckBox({ ...showCheckBox, Soiling: !showCheckBox.Soiling })}

                                />
                            </td>

                            <td style={{ textAlign: "center" }}>  <InputLabel style={{ color: "black", fontSize: "2vmin" }} htmlFor="soiling" >Soiling</InputLabel> </td>

                            <td style={{ textAlign: "center" }}>
                                We have observed a 3.5% gap between the actual soiling loss and PVSYST
                                model
                            </td>
                            <td style={{ textAlign: "center" }}>3.50 %</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox
                                    id="shadow"
                                    checked={showCheckBox.Shadow}
                                    color="success"
                                    onChange={() => setShowCheckBox({ ...showCheckBox, Shadow: !showCheckBox.Shadow })}

                                />
                            </td>

                            <td style={{ textAlign: "center" }}><InputLabel htmlFor="shadow" style={{ color: "black", fontSize: "2vmin" }} > Shadow Loss</InputLabel> </td>

                            <td style={{ textAlign: "center" }}>
                                There is 1.3% gap in actual plant performance compared to PVSYST model
                            </td>
                            <td style={{ textAlign: "center" }}>1.30 %</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox

                                    id="insulator"
                                    checked={showCheckBox.Resistance}
                                    color="success"
                                    onChange={() => setShowCheckBox({ ...showCheckBox, Resistance: !showCheckBox.Resistance })}

                                />
                            </td>

                            <td style={{ textAlign: "center" }}>

                                <InputLabel htmlFor="insulator" style={{ whiteSpace: "pre-wrap", color: "black", fontSize: "2vmin" }} >
                                    Insulation resistance of cable & connector
                                </InputLabel>
                            </td>

                            <td style={{ textAlign: "center" }}>
                                There are number of damage Y connector and DC string cable exposed to
                                the sharp edge of thr module mountaining structure that require
                                attention to avoid inverter tripping due to low insultion resistance

                            </td>
                            <td style={{ textAlign: "center" }}>1.00 %</td>
                        </tr>
                        <tr>
                            <td>
                                <Checkbox
                                    id="overheating"
                                    checked={showCheckBox.Overheating}
                                    color="success"
                                    onChange={() => setShowCheckBox({ ...showCheckBox, Overheating: !showCheckBox.Overheating })}
                                />
                            </td>

                            <td style={{ textAlign: "center" }}>
                                <InputLabel htmlFor="overheating" style={{ whiteSpace: "pre-wrap", color: "black", fontSize: "2vmin" }}>
                                    Overheating DC string and DC main cables

                                </InputLabel>
                            </td>

                            <td style={{ textAlign: "center" }}>
                                IR inspection of the SMBs have indentified several overheating strings
                                and Dc cable joint, which is causing derating of cables and could
                                result into fire in high irradiance days
                            </td>
                            <td style={{ textAlign: "center" }}>1.00 %</td>
                        </tr>
                    </table>
                </div>


            </Grid>
        </Grid>
    );
};

export default LossFlow;
