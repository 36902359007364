import React, { useEffect, useState } from "react";
import "./CountryHeader2.css";
import { useNavigate } from "react-router-dom";
import PageURL from "../../../constants/PageURL";
import logo from "../../../fonts and images/newLogo.PNG";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import {
  B1_Inv1_smbs,
  B1_Inv2_smbs,
  B2_Inv3_smbs,
  B2_Inv4_smbs,
  headerData,
} from "../../../constants/Data";

const CountryHeader2 = (props) => {
  const navigate = useNavigate();
  const data = headerData;
  const [country, setCountry] = useState("");
  const [partnersName, setpartnersName] = useState("");
  const [block, setBlock] = useState("");
  const [projectDetail, setProjectDetail] = useState("");

  const [checkedData, setCheckedData] = React.useState({
    projectDetail: false,
    Diagonistic: false,
    Efficiency: false,
    checkedData: false,
    Prescritive: false,
    string_smb: false,
    correctiveAction: false,
  });
  const [checkedData2, setCheckedData2] = React.useState({
    block1: false,
    block2: false,
  });
  const [checkedData3, setCheckedData3] = React.useState({
    Inverter1: false,
    Inverter2: false,
    Inverter3: false,
    Inverter4: false,
  });
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openLinksDetail, setOpenLinksDetail] = useState({
    country: false,
    partnersName: false,
    projectName: false,
    projectDetail: false,
  });
  const [openLinksValue, setOpenLinksValue] = useState({
    country: "None",
    partnersName: "None",
    projectName: "None",
    projectDetail: "None",
  });

  const [openProjectDetail, setOpenProjectDetail] = useState({
    overViewDetail: false,
    diagonisticModel: false,
    inverterEfficiency: false,
    stringSMB: false,
    block_1: false,
    block_2: false,
    inverter_1: false,
    inverter_2: false,
    inverter_3: false,
    inverter_4: false,
    prescritiveModel: false,
  });

  const onclickBlockHandler = (name) => {
    let checked = { ...checkedData2 };
    for (let key in checked) {
      if (key === name) {
        checked[key] = !checked[key];
      } else {
        checked[key] = false;
      }
    }
    setCheckedData2(checked);
  };

  const onclickInverterHandler = (name) => {
    let checked = { ...checkedData3 };
    for (let key in checked) {
      if (key === name) {
        checked[key] = !checked[key];
      } else {
        checked[key] = false;
      }
    }
    setCheckedData3(checked);
  };
  const onclickHandler = (name) => {
    let checked = { ...checkedData };
    for (let key in checked) {
      if (key === name) {
        checked[key] = !checked[key];
      } else {
        checked[key] = false;
      }
    }
    setCheckedData2({
      ...checkedData2,
      block1: false,
      block2: false,
    });
    setCheckedData3({
      ...checkedData3,
      Inverter1: false,
      Inverter2: false,
      Inverter3: false,
      Inverter4: false,
    });
    setCheckedData(checked);
  };

  const goToPageHandler = (value, path) => {
    setOpenLinksValue({
      ...openLinksValue,
      projectDetail: value,
    });
    setOpenSidebar(false);
    navigate(path);
  };

  useEffect(() => {}, [country]);

  window.onresize = () => {
    if (openSidebar && window.innerWidth >= 950) {
      setOpenSidebar(false);
    }
  };

  window.onscroll = function () {
    console.log("scrolling");
    console.log(window.pageYOffset);
  };

  window.scroll = () => {
    console.log("clicked");
  };

  return (
    <div className="header">
      {/*  ##### GreenEnco Logo Start ##### */}

      <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)}>
        <img src={logo} alt="GreenEnco-Logo" />
      </div>
      {/* <div
        onClick={() => navigate(PageURL.COUNTRYDEFAULT)}
        style={{
          cursor: "pointer",
          display: "flex",
          color: "white",
          alignItems: "center",
          flexDirection: "column",
          gap: "0",
          justifyContent: "center",
          fontFamily: "Georgia Ref Regular",
        }}
      >
        <h3
          style={{
            fontSize: "4vmin",
            letterSpacing: "0.1vmin",
            fontWeight: "200",
            lineHeight: ".9",
          }}
        >
          GreenEnco
        </h3>
        <span
          style={{
            fontSize: "1.25vmin",
            fontWeight: "500",
            letterSpacing: "0.1vmin",
          }}
        >
          Maximizing Asset Values
        </span>
      </div> */}

      {/*  ##### GreenEnco Logo End ##### */}

      <h2>Creating A Better World In A Changing Climate</h2>

      {/*  ##### Code For Header Sidebar In Small Devices Start ##### */}

      <div className="header-open-detail">
        <p
          style={{
            width: "45px",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <i
            onClick={() => setOpenSidebar((val) => !val)}
            class={`fa-solid ${openSidebar ? "fa-bars-staggered" : "fa-bars"}`}
          ></i>
        </p>
      </div>
      <div className="sidebar-2" style={{ height: openSidebar ? "10vh" : "0" }}>
        <span className="dropdown">
          <FormControl
            variant="filled"
            style={{ color: "white" }}
            sx={{ width: "100%", height: "100%", color: "white" }}
          >
            <InputLabel
              id="demo-simple-select-filled-label11"
              style={{ color: "white" }}
              sx={{
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Country
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label11"
              id="demo-simple-select-filled11"
              value={country}
              onChange={(e) => {
                if (e.target.value === "None") {
                  setBlock("");
                  setpartnersName("");
                  setProjectDetail("");
                  setCheckedData({
                    ...checkedData,
                    projectDetail: false,
                    Diagonistic: false,
                    Efficiency: false,
                    checkedData: false,
                    Prescritive: false,
                    string_smb: false,
                    correctiveAction: false,
                  });
                  setCheckedData2({
                    ...checkedData2,
                    block1: false,
                    block2: false,
                  });
                  setCheckedData3({
                    ...checkedData3,
                    Inverter1: false,
                    Inverter2: false,
                    Inverter3: false,
                    Inverter4: false,
                  });
                  setCountry("");
                } else {
                  setCountry(e.target.value);
                }
              }}
            >
              <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>

              {data.map((value, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {value.country}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </span>
        <span className="dropdown">
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              id="demo-simple-select-filled-label1"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Partners Name
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                if (e.target.value === "None") {
                  setBlock("");
                  setProjectDetail("");
                  setpartnersName("");
                  setCheckedData({
                    ...checkedData,
                    projectDetail: false,
                    Diagonistic: false,
                    Efficiency: false,
                    checkedData: false,
                    Prescritive: false,
                    string_smb: false,
                    correctiveAction: false,
                  });
                  setCheckedData2({
                    ...checkedData2,
                    block1: false,
                    block2: false,
                  });
                  setCheckedData3({
                    ...checkedData3,
                    Inverter1: false,
                    Inverter2: false,
                    Inverter3: false,
                    Inverter4: false,
                  });
                  setpartnersName("");
                } else setpartnersName(e.target.value);
              }}
              value={partnersName}
            >
              {/* {!data[country]?.partnersName && <MenuItem><em>None</em> </MenuItem>} */}
              <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>

              {data[country]?.partnersName.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.name}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </span>
        <span className="dropdown">
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              id="demo-simple-select-filled-label2"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Name
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                if (e.target.value === "None") {
                  setProjectDetail("");
                  setCheckedData({
                    ...checkedData,
                    projectDetail: false,
                    Diagonistic: false,
                    Efficiency: false,
                    checkedData: false,
                    Prescritive: false,
                    string_smb: false,
                    correctiveAction: false,
                  });
                  setCheckedData2({
                    ...checkedData2,
                    block1: false,
                    block2: false,
                  });
                  setCheckedData3({
                    ...checkedData3,
                    Inverter1: false,
                    Inverter2: false,
                    Inverter3: false,
                    Inverter4: false,
                  });
                  setBlock("");
                } else setBlock(e.target.value);
              }}
              value={block}
            >
              <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>
              {data[country]?.partnersName
                ?.find((e) => e.name === partnersName)
                ?.blocks?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {value.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
        <span className="dropdown">
          <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
            <InputLabel
              id="demo-simple-select-filled-label3"
              style={{ color: "white" }}
              sx={{
                color: "white",
                height: "100%",
                width: "100%",
                fontWeight: 600,
                fontSize: "2.5vmin",
                letterSpacing: "0.2vmin",
              }}
            >
              Project Detail
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{ color: "white", fontSize: "1.8vmin" }}
              sx={{
                height: "100%",
                width: "100%",
                color: "white",
                letterSpacing: "0.1vmin",
              }}
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              onChange={(e) => {
                setProjectDetail(e.target.value);
              }}
              value={projectDetail}
            >
              {!data[country]?.partnersName
                ?.find((e) => e.name === partnersName)
                ?.blocks.find((e) => e.name === block)?.details && (
                <MenuItem value={"none"}>
                  <em>None</em>{" "}
                </MenuItem>
              )}

              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("projectDetail")}>
                    Overview Details
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData.projectDetail ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData.projectDetail &&
                [
                  {
                    url: PageURL.INDIA_PROJ_OVERVIEW,
                    value: "Project OverView",
                  },
                  {
                    url: PageURL.INDIA_GEN_SUMMARY,
                    value: "Generation Summary",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("Diagonistic")}>
                    Diagonistic Model
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData.Diagonistic ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.Diagonistic &&
                [
                  {
                    url: PageURL.INDIA_DIAGONISTIC_DETAILED,
                    value: "Detailed Summary",
                  },
                  { url: PageURL.INDIA_LOSS_FLOW, value: "Loss Flow Diagram" },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("Efficiency")}>
                    Inverter Efficiency
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData.Efficiency ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData.Efficiency && (
                <MenuItem
                  value={7}
                  onClick={() => {
                    navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                  }}
                >
                  Inverter Efficiency
                </MenuItem>
              )}
              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("string_smb")}>
                    String / SMB
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData.string_smb ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickBlockHandler("block1")}>
                    Block 1
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData2.block1 ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData2.block1 && checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickInverterHandler("Inverter1")}>
                    Inverter1
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter1 ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData3.Inverter1 &&
                checkedData2.block1 &&
                B1_Inv1_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {checkedData2.block1 && checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickInverterHandler("Inverter2")}>
                    Inverter2
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter2 ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}

              {checkedData3.Inverter2 &&
                checkedData2.block1 &&
                B1_Inv2_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickBlockHandler("block2")}>
                    Block 2
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData2.block2 ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData2.block2 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickInverterHandler("Inverter3")}>
                    Inverter3
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter3 ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData3.Inverter3 &&
                checkedData2.block2 &&
                B2_Inv3_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {checkedData2.block2 && (
                <ListSubheader className="SubHeader">
                  {" "}
                  <span onClick={() => onclickInverterHandler("Inverter4")}>
                    Inverter4{" "}
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter4 ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData3.Inverter4 &&
                checkedData2.block2 &&
                B2_Inv4_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {block.length > 0 && (
                <ListSubheader className="SubHeader last_item">
                  {" "}
                  <span onClick={() => onclickHandler("Prescritive")}>
                    Prescriptive Model{" "}
                    <i
                      className={`fa-solid fa-angle-${
                        checkedData.Prescritive ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </ListSubheader>
              )}
              {checkedData.Prescritive &&
                [
                  {
                    url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
                    value: "Fault Tree Diagram",
                  },
                  {
                    url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
                    value: "Loss Flow Diagram",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </span>
      </div>
      {/*  ##### Code For Header Sidebar In Small Devices End ##### */}

      {/*  ##### Code For Header Sidebar In Large Devices Start ##### */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "0vmax"
        }}
        className="detail-link"
      >
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Country
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true,
                }
              }
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={country}
              onChange={(e) => {
                if (e.target.value === "None") {
                  setBlock("");
                  setpartnersName("");
                  setProjectDetail("");
                  setCheckedData({
                    ...checkedData,
                    projectDetail: false,
                    Diagonistic: false,
                    Efficiency: false,
                    checkedData: false,
                    Prescritive: false,
                    string_smb: false,
                    correctiveAction: false,
                  });
                  setCheckedData2({
                    ...checkedData2,
                    block1: false,
                    block2: false,
                  });
                  setCheckedData3({
                    ...checkedData3,
                    Inverter1: false,
                    Inverter2: false,
                    Inverter3: false,
                    Inverter4: false,
                  });
                  setCountry("");
                } else {
                  setCountry(e.target.value);
                }
              }}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>

              {data.map((value, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {value.country}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label1"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Partners Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label1"
              id="demo-simple-select-filled1"
              onChange={(e) => {
                if (e.target.value === "None") {
                  setBlock("");
                  setProjectDetail("");
                  setpartnersName("");
                  setCheckedData({
                    ...checkedData,
                    projectDetail: false,
                    Diagonistic: false,
                    Efficiency: false,
                    checkedData: false,
                    Prescritive: false,
                    string_smb: false,
                    correctiveAction: false,
                  });
                  setCheckedData2({
                    ...checkedData2,
                    block1: false,
                    block2: false,
                  });
                  setCheckedData3({
                    ...checkedData3,
                    Inverter1: false,
                    Inverter2: false,
                    Inverter3: false,
                    Inverter4: false,
                  });
                  setpartnersName("");
                } else setpartnersName(e.target.value);
              }}
              value={partnersName}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {/* {!data[country]?.partnersName && <MenuItem><em>None</em> </MenuItem>} */}
              <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>

              {data[country]?.partnersName.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.name}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label2"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Name
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label2"
              id="demo-simple-select-filled2"
              onChange={(e) => {
                if (e.target.value === "None") {
                  setProjectDetail("");
                  setCheckedData({
                    ...checkedData,
                    projectDetail: false,
                    Diagonistic: false,
                    Efficiency: false,
                    checkedData: false,
                    Prescritive: false,
                    string_smb: false,
                    correctiveAction: false,
                  });
                  setCheckedData2({
                    ...checkedData2,
                    block1: false,
                    block2: false,
                  });
                  setCheckedData3({
                    ...checkedData3,
                    Inverter1: false,
                    Inverter2: false,
                    Inverter3: false,
                    Inverter4: false,
                  });
                  setBlock("");
                } else setBlock(e.target.value);
              }}
              value={block}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              <MenuItem value={"None"}>
                <em>None</em>{" "}
              </MenuItem>
              {data[country]?.partnersName
                ?.find((e) => e.name === partnersName)
                ?.blocks?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.name}>
                      {value.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="dropdown">
          <FormControl variant="filled" sx={{ width: "9vmax" }} size="small">
            <InputLabel
              id="demo-simple-select-filled-label3"
              style={{ color: "black", fontWeight: 700, fontSize: "0.9vmax" }}
            >
              Project Detail
            </InputLabel>
            <Select
              MenuProps={
                {
                  // disableScrollLock:true
                }
              }
              labelId="demo-simple-select-filled-label3"
              id="demo-simple-select-filled3"
              onChange={(e) => {
                setProjectDetail(e.target.value);
              }}
              value={projectDetail}
              style={{ backgroundColor: "rgb(236, 85, 15)", fontSize: "1vmax" }}
            >
              {!data[country]?.partnersName
                ?.find((e) => e.name === partnersName)
                ?.blocks.find((e) => e.name === block)?.details && (
                <MenuItem value={"none"}>
                  <em>None</em>{" "}
                </MenuItem>
              )}

              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("projectDetail")}>
                    Overview Details
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.projectDetail ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData.projectDetail ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData.projectDetail &&
                [
                  {
                    url: PageURL.INDIA_PROJ_OVERVIEW,
                    value: "Project OverView",
                  },
                  {
                    url: PageURL.INDIA_GEN_SUMMARY,
                    value: "Generation Summary",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("Diagonistic")}>
                    Diagonistic Model
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Diagonistic ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData.Diagonistic ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData.Diagonistic &&
                [
                  {
                    url: PageURL.INDIA_DIAGONISTIC_DETAILED,
                    value: "Detailed Summary",
                  },
                  { url: PageURL.INDIA_LOSS_FLOW, value: "Loss Flow Diagram" },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("Efficiency")}>
                    Inverter Efficiency
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Efficiency ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData.Efficiency ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}

              {checkedData.Efficiency && (
                <MenuItem
                  value={7}
                  onClick={() => {
                    navigate(PageURL.INDIA_INVERTER_EFFICIENCY);
                  }}
                >
                  Inverter Efficiency
                </MenuItem>
              )}
              {block.length > 0 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickHandler("string_smb")}>
                    String / SMB
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.string_smb ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData.string_smb ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickBlockHandler("block1")}>
                    Block 1
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData2.block1 ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData2.block1 ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}

              {checkedData2.block1 && checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickInverterHandler("Inverter1")}>
                    Inverter1
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData3.Inverter1 ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter1 ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData3.Inverter1 &&
                checkedData2.block1 &&
                B1_Inv1_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {checkedData2.block1 && checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickInverterHandler("Inverter2")}>
                    Inverter2
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData3.Inverter2 ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter2 ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData3.Inverter2 &&
                checkedData2.block1 &&
                B1_Inv2_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {checkedData.string_smb && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickBlockHandler("block2")}>
                    Block 2
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData2.block2 ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData2.block2 ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData2.block2 && (
                <ListSubheader className="SubHeader">
                  <span onClick={() => onclickInverterHandler("Inverter3")}>
                    Inverter3
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData3.Inverter3 ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter3 ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData3.Inverter3 &&
                checkedData2.block2 &&
                B2_Inv3_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {checkedData2.block2 && (
                <ListSubheader className="SubHeader">
                  {" "}
                  <span onClick={() => onclickInverterHandler("Inverter4")}>
                    Inverter4{" "}
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData3.Inverter4 ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData3.Inverter4 ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}

              {checkedData3.Inverter4 &&
                checkedData2.block2 &&
                B2_Inv4_smbs.map((e, index) => {
                  return (
                    <MenuItem
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}

              {block.length > 0 && (
                <ListSubheader className="SubHeader last_item">
                  {" "}
                  <span onClick={() => onclickHandler("Prescritive")}>
                    Prescriptive Model{" "}
                    <i
                      className={`fa-solid fa-angle-down ${
                        checkedData.Prescritive ? "active" : ""
                      }`}
                    ></i>
                    {/* <i
                      className={`fa-solid fa-angle-${
                        checkedData.Prescritive ? "up" : "down"
                      }`}
                    ></i> */}
                  </span>
                </ListSubheader>
              )}
              {checkedData.Prescritive &&
                [
                  {
                    url: PageURL.INDIA_PRES_MODEL_Fault_Tree,
                    value: "Fault Tree Diagram",
                  },
                  {
                    url: PageURL.INDIA_PRES_MODEL_Loss_Flow,
                    value: "Loss Flow Diagram",
                  },
                ].map((e, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={e.url}
                      onClick={() => {
                        navigate(e.url);
                      }}
                    >
                      {e.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>

      {/*  ##### Code For Header Sidebar In Large Devices End ##### */}
    </div>
  );
};

export default CountryHeader2;
