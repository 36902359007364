// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #####  Code For Tabel component  #####  */

table {
  border-collapse: collapse;
  margin-top: 10px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA,4CAA4C;;AAE5C;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["/* #####  Code For Tabel component  #####  */\n\ntable {\n  border-collapse: collapse;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
