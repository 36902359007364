import React, { useEffect, useState } from "react";
import "./CountryHeader.css";
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FolderIcon from '@mui/icons-material/Folder';
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageURL from "../../../constants/PageURL";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import { clear_errors } from "../../../constants/dataConstants";
import logo from '../../../fonts and images/newLogo.PNG'

const CountryHeader = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { user, isLogout, error } = useSelector(state => state.user);
  const [openSidebar, setOpenSidebar] = useState(false)

  const [activeLink, setActiveLink] = useState({
    admin: false,
    analytics: false
  })

  const logoutHandler = () => {
    dispatch(userLogout())
  }
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: clear_errors })
      dispatch({ type: 'USER_LOGOUT' })
      navigate(PageURL.BASE)


    }
    if (isLogout) {
      toast.success("User Logged out sucessfully!")
      dispatch({ type: 'USER_LOGOUT' })
      navigate(PageURL.BASE)
    }


  }, [dispatch, navigate, user, props, isLogout])

  window.onresize = () => {
    if (window.innerWidth > 700) {
      setOpenSidebar(false)
    }
  }
  return (
    <div className="header">

      {/*  ##### GreenEnco Logo Start ##### */}
      <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)}>
        <img src={logo} alt="GreenEnco-Logo"/>
      </div>

      {/* <div onClick={() => navigate(PageURL.COUNTRYDEFAULT)} style={{ cursor: "pointer", display: "flex", color: "white", alignItems: "center", flexDirection: "column", gap: "0", justifyContent: "center", fontFamily: "Georgia Ref Regular" }}>
        <span style={{ fontSize: "4vmin", letterSpacing: "0.1vmin", fontWeight: "200", lineHeight: ".9" }}>GreenEnco</span>
        <span style={{ fontSize: "1.25vmin", fontWeight: "500", letterSpacing: "0.1vmin" }}>Maximizing Asset Values</span>
      </div> */}

      {/*  ##### GreenEnco Logo End ##### */}

      <h4 >
        Creating A Better World In A Changing Climate
      </h4>

      <div className="bar">
        <p style={{ width: "40px", height: "40px", textAlign: "center", overflow: "hidden" }}>

          <i onClick={() => {
            setOpenSidebar(true)
          }} className={`fa-solid fa-bars bar-icon`} style={{ position: "relative", right: !openSidebar ? "10%" : "-130%" }}></i>
          <i onClick={() => {
            setOpenSidebar(false)
          }} className={`fa-solid fa-xmark xmark-icon`} style={{ position: "absolute", top: openSidebar ? "10%" : "-130%", right: openSidebar ? "25%" : "0%" }}></i>
        </p>

        {/*  ##### Code For Header Sidebar ##### */}

        <div className="sidebar-1" style={{ height: openSidebar ? "110px" : 0 }}>
          <Link to={PageURL.PROJECT_DETAILS}
          >Project Details  </Link>
          {(PageURL.COUNTRYDEFAULT === location.pathname || PageURL.PROJECT_ANALYTICS === location.pathname) && user && user.LevelPermission === 4 && (
            <Link onClick={() => {
              props?.setDashBoard(true)
              setActiveLink({
                ...activeLink,
                analytics: false,
                admin: true
              })
            }}> Admin DashBoard </Link>
          )}
          <Link onClick={logoutHandler}>Logout  </Link>
        </div>
      </div>
      <div className="header-links-1" >
        <span
          onClick={() => {
            navigate(PageURL.PROJECT_DETAILS)
          }}
        ><FolderIcon/>Project Details </span>
        {(PageURL.COUNTRYDEFAULT === location.pathname || location.pathname === PageURL.PROJECT_ANALYTICS) && user && user?.LevelPermission === 4 && (
          <span style={{ color: activeLink.admin  ? "#ed7d31" : "white" }}
            onClick={() => {
                props?.setDashBoard(true);
              setActiveLink({
                ...activeLink,
                analytics: false,
                admin: true
              })
            }}

          ><AdminPanelSettingsIcon/>Admin DashBoard  </span>
        )}
        <span onClick={logoutHandler}
        ><LogoutIcon/>Logout </span>
      </div>
    </div>
  );
};

export default CountryHeader;
