// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #####  Code for signup component  ##### */

.signup-box {
  padding: 1.5vmax 2vmax;
  width: 100%;
  background-color: rgba(4, 126, 126, 0.97);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  --webkit-backdrop-filter: blur(10px);
  box-shadow: 50px 50px 17px rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2vmax;
}

#level {
  width: 100%;
  height: 7vh
}

@media screen and (max-width:950px) {
  .login-page {
    height: 125%;
  }

  .signup-box {
    padding: 1.5vmax 2vmax;
  }
}

@media screen and (max-width:500px) {
  .login .login-container {
    width: 90%;
  }

  .login-page {
    height: 110%;
  }

  .double_inputs {
    flex-direction: column;
  }

  .signup-box {
    padding: 1.5vmax 3vmax;
  }

}`, "",{"version":3,"sources":["webpack://./src/modules/auth/components/SignUp.css"],"names":[],"mappings":"AAAA,4CAA4C;;AAE5C;EACE,sBAAsB;EACtB,WAAW;EACX,yCAAyC;EACzC,mBAAmB;EACnB,2BAA2B;EAC3B,oCAAoC;EACpC,6CAA6C;EAC7C,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,WAAW;EACX;AACF;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;AAEF","sourcesContent":["/* #####  Code for signup component  ##### */\n\n.signup-box {\n  padding: 1.5vmax 2vmax;\n  width: 100%;\n  background-color: rgba(4, 126, 126, 0.97);\n  border-radius: 10px;\n  backdrop-filter: blur(10px);\n  --webkit-backdrop-filter: blur(10px);\n  box-shadow: 50px 50px 17px rgba(0, 0, 0, 0.6);\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1.2vmax;\n}\n\n#level {\n  width: 100%;\n  height: 7vh\n}\n\n@media screen and (max-width:950px) {\n  .login-page {\n    height: 125%;\n  }\n\n  .signup-box {\n    padding: 1.5vmax 2vmax;\n  }\n}\n\n@media screen and (max-width:500px) {\n  .login .login-container {\n    width: 90%;\n  }\n\n  .login-page {\n    height: 110%;\n  }\n\n  .double_inputs {\n    flex-direction: column;\n  }\n\n  .signup-box {\n    padding: 1.5vmax 3vmax;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
