import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { GHI_GTI_data_action, normalizedEnergyDetails } from "../../../../actions/inverterActions";
import SpinLoader from "../../../../components/SpinLoader";
import Printer from "../../../../components/Printer";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis, Area, AreaChart, ResponsiveContainer } from "recharts";
import { saveAs } from 'file-saver'
import { CSVLink } from "react-csv";
import "./Detailed.css";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import { exportComponentAsJPEG } from "react-component-export-image";
import { AxisLabel, saveToSvg } from "../../../../constants/Utils";



const Detailed = () => {
  const { GHI_GTI_data, loading } = useSelector(state => state.GHI_GTI_data)
  const { energy } = useSelector(state => state.energy)

  const [showPVsystContractualData, setShowPVsystContractualData] = useState({
    pvsystEnergy: true,
    contractualEnergy: true,
    all: true

  })

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showNetNormalisedShortfallData, setShowNetNormalisedShortfallData] = useState({
    netEnergy: true,
    normalisedEnergy: true,
    shortfall: true,
    all: true

  })

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showPvsystActualGTI, setShowPvsystActualGTI] = useState({
    PvsystGTI: true,
    ActualGTI: true,
    PvsystVsActualGTI: true,
    all: true

  })

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showPvsystActualGHI, setShowPvsystActualGHI] = useState({
    PvsystGHI: true,
    ActualGHI: true,
    PvsystVsActualGHI: true,
    all: true

  })

  /* ###   Initialize State to animate progress bar   ### */

  const [progressValueAnimate, setProgressValueAnimate] = useState({
    progress1: false,
    progress2: false,
    progress3: false,
    progress4: false,
    progress5: false,
    progress6: false
  })

  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef1 = useRef(null);
  const downloadRef2 = useRef(null);
  const downloadRef3 = useRef(null);
  const downloadRef4 = useRef(null);


  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef1 = useRef(null);
  const graphRef2 = useRef(null);
  const graphRef3 = useRef(null);
  const graphRef4 = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GHI_GTI_data_action())
    dispatch(normalizedEnergyDetails())
  }, [dispatch])



  return (
    <>
      {
        loading ? <SpinLoader /> :
        GHI_GTI_data?.data &&
        <Grid container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          textAlign={"center"}
        >

          { /* ###   Detailed Container 1 Start   ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto 0",
              padding: "1vmax",
            }}
            className="detail_overview_container"
          >
            <div className="detail_overview_1">
              <div >
                <div>
                  <div>
                    <progress className="progress_1"
                      style={{
                        accentColor: "green",
                        borderRadius: "14px",
                        border: progressValueAnimate.progress1 ? "3px solid red" : "none"
                      }}
                      value={parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2) || 0} max="2000" />
                  </div>
                  <div>
                    <span>PVsyst GTI (kWh/m^2)</span>
                    <span
                      style={{
                        position: "absolute",
                        left: `${parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2) * 100 / 2000 - 3}%`,
                        bottom: "60%"
                      }}
                    ><i class="fa-solid fa-caret-up"></i></span>

                    <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2) || 0}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <progress className="progress_2"
                      style={{
                        accentColor: "green",
                        borderRadius: "14px",
                        border: progressValueAnimate.progress2 ? "3px solid black" : "none"
                      }}
                      value={
                        parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2)
                      } max="2000" />
                  </div>
                  <div>
                    <span>Actual GTI (kWh/m^2)</span>
                    <span
                      style={{
                        position: "absolute",
                        left: `${parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2) * 100 / 2000 - 3}%`,
                        bottom: "60%"
                      }}
                    ><i class="fa-solid fa-caret-up"></i></span>
                    <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2) || 0}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <progress className="progress_3" style={{ accentColor: "green" }} value={

                      parseFloat((parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2) -
                        parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2)) * 100 /
                        parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2)).toFixed(2) || 0
                    } max="100" />
                  </div>
                  <div>
                    <span>Excess/Shortfall (%)</span>
                    <span
                      style={{
                        position: "absolute",
                        left: `${(parseFloat((parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2) -
                          parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2)) * 100 /
                          parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2)).toFixed(2) || 0)

                          - 3}%`,
                        bottom: "60%"
                      }}
                    ><i class="fa-solid fa-caret-up"></i></span>
                    <span>{parseFloat((parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2) -
                      parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2)) * 100 /
                      parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2)).toFixed(2) || 0}</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GTI, 0)).toFixed(2)}</span>
                  <span>PVsyst GTI  (kWh/m^2) </span>
                  <div style={{ width: "80%", height: 30 }} >
                    <ResponsiveContainer >
                      <ComposedChart
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0
                        }}
                        data={GHI_GTI_data?.data || []} >
                        <XAxis dataKey="name" hide />
                        {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GTI"]} />} /> */}
                        <Bar barSize={4} radius={14} dataKey="pvsyst_GTI" fill="#8884d8" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <span className="progress_button_1"
                    onMouseEnter={() => setProgressValueAnimate({ ...progressValueAnimate, progress1: true })}
                    onMouseOut={() => setProgressValueAnimate({ ...progressValueAnimate, progress1: false })}
                  >View</span>
                </div>
                <div>
                  <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GTI, 0)).toFixed(2)}</span>
                  <span>Actual GTI (kWh/m^2) </span>
                  <div style={{ width: "80%", height: 30 }} >
                    <ResponsiveContainer >
                      <ComposedChart
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0
                        }}
                        data={GHI_GTI_data?.data || []} >
                        <XAxis dataKey="name" hide />
                        {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GTI"]} />} /> */}
                        <Bar barSize={4} radius={14} dataKey="Actual_GTI" fill="#8884d8" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <span
                    onMouseEnter={() => setProgressValueAnimate({ ...progressValueAnimate, progress2: true })}
                    onMouseOut={() => setProgressValueAnimate({ ...progressValueAnimate, progress2: false })}
                    className="progress_button_2">View</span>
                </div>
              </div>
            </div>
            <div className="detail_overview_1">
              <div >
                <div>
                  <div>
                    <progress className="progress_4"
                      style={{
                        accentColor: "green",
                        borderRadius: "14px",
                        border: progressValueAnimate.progress4 ? "3px solid red" : "none"
                      }}
                      value={parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2)} max="2000" />
                  </div>
                  <div>
                    <span>PVsyst GHI (kWh/m^2)</span>
                    <span
                      style={{
                        position: "absolute",
                        left: `${parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2) * 100 / 2000 - 3}%`,
                        bottom: "60%"
                      }}
                    ><i class="fa-solid fa-caret-up"></i></span>
                    <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2)}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <progress className="progress_5"
                      style={{
                        accentColor: "green",
                        borderRadius: "14px",
                        border: progressValueAnimate.progress5 ? "3px solid black" : "none"
                      }}
                      value={parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2) || 0} max="2000" />
                  </div>
                  <div>
                    <span>Actual GHI (kWh/m^2)</span>
                    {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                    <span
                      style={{
                        position: "absolute",
                        left: `${parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2) * 100 / 2000 - 3 || 0}%`,
                        bottom: "60%"
                      }}
                    ><i class="fa-solid fa-caret-up"></i></span>
                    <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2) || 0}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <progress className="progress_3" style={{ accentColor: "blue" }} value={
                      parseFloat((parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2) -
                        parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2)) * 100 /
                        parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2)).toFixed(2) || 0

                    } max="100" />
                  </div>
                  <div>
                    <span>Excess/ShortFall (%)</span>
                    {/* <span style={{fontSize:"1.2rem"}}><ArrowDropUpIcon  /></span> */}
                    <span
                      style={{
                        position: "absolute",
                        left: `${parseFloat((parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2) -
                          parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2)) * 100 /
                          parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2)).toFixed(2) - 3

                          }%`,
                        bottom: "60%"
                      }}
                    ><i class="fa-solid fa-caret-up"></i></span>
                    <span>{parseFloat((parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2) -
                      parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2)) * 100 /
                      parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2)).toFixed(2) || 0}</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.pvsyst_GHI, 0)).toFixed(2) || 0}</span>
                  <span>PVSYST GHI (kWh/m^2)</span>
                  <div style={{ width: "80%", height: 30 }} >
                    <ResponsiveContainer >
                      <ComposedChart
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0
                        }}
                        data={GHI_GTI_data?.data || []} >
                        <XAxis dataKey="name" hide />
                        {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GHI"]} />} /> */}
                        <Bar barSize={4} radius={14} dataKey="pvsyst_GHI" fill="#8884d8" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <span className="progress_button_1"
                    onMouseEnter={() => setProgressValueAnimate({ ...progressValueAnimate, progress4: true })}
                    onMouseOut={() => setProgressValueAnimate({ ...progressValueAnimate, progress4: false })}
                  >View</span>
                </div>
                <div>
                  <span>{parseFloat(GHI_GTI_data?.data?.reduce((acc, curr) => acc + curr.Actual_GHI, 0)).toFixed(2) || 0}</span>
                  <span>Actual GHI (kWh/m^2) </span>
                  <div style={{ width: "80%", height: 30 }} >
                    <ResponsiveContainer >
                      <ComposedChart
                        margin={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0
                        }}
                        data={GHI_GTI_data?.data || []} >
                        <XAxis dataKey="name" hide />
                        {/* <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Actual GHI"]} />} /> */}
                        <Bar barSize={4} radius={14} dataKey="Actual_GHI" fill="#8884d8" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <span
                    onMouseEnter={() => setProgressValueAnimate({ ...progressValueAnimate, progress5: true })}
                    onMouseOut={() => setProgressValueAnimate({ ...progressValueAnimate, progress5: false })}
                    className="progress_button_2">View</span>
                </div>
              </div>
            </div>
            <div className="detail_overview_2">
              <div style={{ width: "100%", height: 100 }} >
                <ResponsiveContainer >
                  <AreaChart
                    margin={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0
                    }}
                    data={energy?.data4 || []}
                  >
                    <XAxis dataKey="name" hide />
                    <YAxis type="number" hide
                      dataKey={() => Math.ceil(energy?.data4?.reduce((acc, curr, index) => Math.max(curr.pvsyst_module_temp, curr.actual_module_temp, acc), -Infinity))}
                      domain={[Math.floor(energy?.data4?.reduce((acc, curr, index) => Math.min(curr.pvsyst_module_temp, curr.actual_module_temp, acc), Infinity)), 'dataMax']}
                    />
                    <Area dataKey="pvsyst_module_temp" stroke="rgb(11, 165, 119)" strokeWidth={0.2} fill="rgb(20, 213, 149)" />
                    <Area dataKey="actual_module_temp" stroke="rgb(11, 165, 119)" strokeWidth={0.2} fill="rgb(91, 248, 201)" />
                    <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst Module Temp", "Actual Module Temp"]} />} />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div>
                <div>
                  <span>{parseFloat(energy?.data4?.reduce((acc, curr) => acc + curr.pvsyst_module_temp, 0) / 12).toFixed(2) || 0}</span>
                  <span style={{ fontSize: "0.6rem" }}>PVsyst Module Temp (℃)</span>
                </div>
                <span style={{ width: "2px", background: "rgb(166, 176, 173)", height: "90%" }}></span>
                <div>
                  <span>{parseFloat(energy?.data4?.reduce((acc, curr) => acc + curr.actual_module_temp, 0) / 12).toFixed(2) || 0}</span>
                  <span style={{ fontSize: "0.6rem" }}>Actual Module Temp (℃)</span>
                </div>
              </div>
            </div>
          </Grid>

          { /* ###   Detailed Container 1 end ### */}

          { /* ###   Detailed Container 2 start ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto 0",
              padding: "1vmax",
            }}
          >
            <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"1vmax"}  >
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                position={"relative"}
                bgcolor={"white"}
                padding={"1vmin"}
              // borderRadius={"14px"}
              >
                <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                  <Printer clickhandler={() => downloadRef1.current.link.click()}
                    jpgDownload={() =>
                      setTimeout(async () => {
                        document.getElementsByClassName("cpy_right")[0].style.display = "block";
                        exportComponentAsJPEG(graphRef1, { fileName: "graph" })
                        document.getElementsByClassName("cpy_right")[0].style.display = "none";

                      }, 100)
                    }
                    svgDownload={async () => {
                      document.getElementsByClassName("cpy_right")[0].style.display = "block";
                      const svgData = await saveToSvg(graphRef1.current.container)
                      document.getElementsByClassName("cpy_right")[0].style.display = "none";

                      saveAs(svgData, 'graph.svg')
                    }}
                  />
                </div>
                <CSVLink
                  data={GHI_GTI_data?.data?.reduce((acc, curr) => {
                    acc.push({
                      Month: curr.name,
                      PVsystGTI: curr.pvsyst_GTI,
                      ActualGTI: curr.Actual_GTI,
                      PVsystGTIvsActualGTI: curr.pvsyst_GTI_vs_Actual_GTI
                    })
                    return acc;
                  }, []) || []}
                  filename='data.csv'
                  className='hidden'
                  target='_blank'
                  ref={downloadRef1}
                />
                <h5 style={{ textAlign: "center", fontSize: "2vmin", padding: "1vmax 0" }}>
                  PVsyst GTI (kWh/m^2) vs Actual GTI (kWh/m^2) vs PVsyst Vs Actual GTI (kWh/m^2)
                </h5>
                <div style={{ width: "100%", height: "36vh" }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      data={GHI_GTI_data?.data || []}
                      margin={{
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 10,
                      }}
                      barCategoryGap={"20%"}
                      ref={graphRef1}
                    >
                      <CartesianGrid stroke="grey" strokeWidth={0.3} />
                      <XAxis
                        dataKey="name"
                        fontSize={10} fontWeight={600}
                        tickLine={false} axisLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        tickFormatter={(v) => v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v}
                        yAxisId="left-axis"
                        //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}} 
                        // label={<AxisLabel axisType='yAxis' x={-20} y={-5}>PVsyst GTI & Actual GTI</AxisLabel>}
                        label={{ value: "PVsyst GTI & Actual GTI", angle: -90, position: "insideBottomLeft", fontSize: "2vmin" }}

                        dataKey={() => Math.ceil(GHI_GTI_data?.data?.reduce((acc, curr, index) => Math.max(curr.pvsyst_GTI, curr.Actual_GTI, acc), -Infinity)) + 10}
                        domain={[Math.floor(GHI_GTI_data?.data?.reduce((acc, curr, index) => Math.min(curr.pvsyst_GTI, curr.Actual_GTI, acc), Infinity)) - 10, 'dataMax']}
                        tickLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        yAxisId="right-axis"
                        orientation="right"
                        // label={<AxisLabel axisType='yAxis' x={30} y={300}>PVsyst Vs Actual GTI</AxisLabel>}
                        label={{ value: "PVsyst GTI Vs Actual GTI", angle: -90, position: "insideBottomRight", fontSize: "2vmin", dy: -160 }}
                        tickLine={false} tickMargin={8}
                      />
                      <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GTI", "Actual GTI", "PVsyst GTI vs Actual GTI"]} />} />
                      <Legend
                        content={<CustomizeLegend active={false} payload={[]} LegendValues={["PVsyst GTI", "Actual GTI", "PVsyst GTI vs Actual GTI"]} data={showPvsystActualGTI} setData={setShowPvsystActualGTI} />}
                      />
                      <Bar
                        hide={showPvsystActualGTI.PvsystGTI ? false : true}
                        dataKey="pvsyst_GTI"
                        fill={`${colors3[0]}`}
                        yAxisId="left-axis"
                      />
                      <Bar
                        hide={showPvsystActualGTI.ActualGTI ? false : true}
                        dataKey="Actual_GTI"
                        fill={`${colors3[1]}`}
                        yAxisId="left-axis"
                      />
                      <Line
                        hide={showPvsystActualGTI.PvsystVsActualGTI ? false : true}

                        type="monotone"
                        dataKey="pvsyst_GTI_vs_Actual_GTI"
                        strokeWidth={3}
                        stroke={`${colors1[4]}`}
                        yAxisId="right-axis"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                position={"relative"}
                bgcolor={"white"}
                // borderRadius={"14px"}
                padding={"1vmin"}

              >
                <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                  <Printer clickhandler={() => downloadRef2.current.link.click()}
                    jpgDownload={() =>
                      setTimeout(async () => {
                        document.getElementsByClassName("cpy_right")[1].style.display = "block";
                        exportComponentAsJPEG(graphRef2, { fileName: "graph" })
                        document.getElementsByClassName("cpy_right")[1].style.display = "none";

                      }, 100)
                    }
                    svgDownload={async () => {
                      document.getElementsByClassName("cpy_right")[1].style.display = "block";
                      const svgData = await saveToSvg(graphRef2.current.container)
                      document.getElementsByClassName("cpy_right")[1].style.display = "none";
                      saveAs(svgData, 'graph.svg')
                    }}
                  />
                </div>
                <CSVLink
                  data={GHI_GTI_data?.data?.reduce((acc, curr) => {
                    acc.push({
                      Month: curr.name,
                      PVsystGHI: curr.pvsyst_GHI,
                      ActualGHI: curr.Actual_GHI,
                      PVsystGHIvsActualGHI: curr.pvsyst_GHI_vs_Actual_GHI
                    })
                    return acc;
                  }, []) || []}
                  filename='data.csv'
                  className='hidden'
                  target='_blank'
                  ref={downloadRef2}
                />

                <h5 style={{ textAlign: "center", fontSize: "2vmin", padding: "1vmax 0" }}>
                  PVsyst GHI (kWh/m^2) vs Actual GHI (kWh/m^2) vs PVsyst Vs Actual GHI (kWh/m^2)
                </h5>
                <div style={{ width: "100%", height: "36vh" }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      data={GHI_GTI_data?.data || []}
                      margin={{
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 10,
                      }}
                      barCategoryGap={"20%"}
                      ref={graphRef2}
                    >
                      <CartesianGrid stroke="grey" strokeWidth={0.3} />
                      <XAxis
                        dataKey="name"
                        fontSize={10} fontWeight={600}
                        tickLine={false} axisLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        tickFormatter={(v) => v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v}
                        yAxisId="left-axis"
                        //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}} 
                        // label={<AxisLabel axisType='yAxis' x={-23} y={-5}>PVsyst GHI & Actual GHI</AxisLabel>}
                        label={{ value: "PVsyst GHI & Actual GHI", angle: -90, position: "insideBottomLeft", fontSize: "2vmin" }}
                        dataKey={() => Math.ceil(GHI_GTI_data?.data?.reduce((acc, curr, index) => Math.max(curr.pvsyst_GHI, curr.Actual_GHI, acc), -Infinity)) + 10}
                        domain={[Math.floor(GHI_GTI_data?.data?.reduce((acc, curr, index) => Math.min(curr.pvsyst_GHI, curr.Actual_GHI, acc), Infinity)) - 10, 'dataMax']}
                        tickLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        yAxisId="right-axis"
                        orientation="right"
                        // label={<AxisLabel axisType='yAxis' x={28} y={300}>PVsyst Vs Actual GHI</AxisLabel>}
                        label={{ value: "PVsyst Vs Actual GHI", angle: -90, position: "insideBottomRight", fontSize: "2vmin", dy: -140, dx: 10 }}
                        tickLine={false} tickMargin={8}
                      />
                      <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst GHI", "Actual GHI", "PVsyst GHI vs Actual GHI"]} />} />
                      <Legend
                        content={<CustomizeLegend active={false} payload={[]} LegendValues={["PVsyst GHI", "Actual GHI", "PVsyst GHI vs Actual GHI"]} data={showPvsystActualGHI} setData={setShowPvsystActualGHI} />}
                      />
                      <Bar
                        hide={showPvsystActualGHI.PvsystGHI ? false : true}
                        dataKey="pvsyst_GHI"
                        fill={`${colors3[0]}`}
                        yAxisId="left-axis"
                      />
                      <Bar
                        hide={showPvsystActualGHI.ActualGHI ? false : true}
                        dataKey="Actual_GHI"
                        fill={`${colors3[1]}`}
                        yAxisId="left-axis"
                      />
                      <Line
                        hide={showPvsystActualGHI.PvsystVsActualGHI ? false : true}
                        type="monotone"
                        dataKey="pvsyst_GHI_vs_Actual_GHI"
                        strokeWidth={3}
                        stroke={`${colors1[4]}`}
                        yAxisId="right-axis"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>

          { /* ###   Detailed Container 2 end ### */}

          { /* ###   Detailed Container 3 start ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background: "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto",
              padding: "1vmax",
            }}>
            <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"1vmax"}  >
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                position={"relative"}
                bgcolor={"white"}
                // borderRadius={"14px"}
                padding={"1vmin"}
              >
                <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                  <Printer clickhandler={() => downloadRef3.current.link.click()}
                    jpgDownload={() =>
                      setTimeout(async () => {
                        document.getElementsByClassName("cpy_right")[2].style.display = "block";
                        exportComponentAsJPEG(graphRef3, { fileName: "graph" })
                        document.getElementsByClassName("cpy_right")[2].style.display = "none";
                      }, 100)
                    }
                    svgDownload={async () => {
                      document.getElementsByClassName("cpy_right")[2].style.display = "block";
                      const svgData = await saveToSvg(graphRef3.current.container)
                      document.getElementsByClassName("cpy_right")[2].style.display = "none";
                      saveAs(svgData, 'graph.svg')
                    }}
                  />
                </div>
                <CSVLink
                  data={energy?.data2?.reduce((acc, curr) => {
                    acc.push({
                      Month: curr.name,
                      NetEnergy: curr.netEnergy,
                      NormalisedEnergy: curr.normalisedEnergy,
                      ExcessShortfall: curr.shortfall
                    })
                    return acc;
                  }, []) || []}
                  filename='data.csv'
                  className='hidden'
                  target='_blank'
                  ref={downloadRef3}
                />
                <h4 style={{ textAlign: "center", fontSize: "2vmin", padding: "1vmax 0" }}>
                  Net Energy (KWh) vs Normalised Energy (KWh) vs Shortfall (%)
                </h4>
                <div style={{ width: "100%", height: "36vh" }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      margin={{
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 20,
                      }}
                      barCategoryGap={"20%"}
                      data={energy?.data2 || []}
                      ref={graphRef3}
                    >
                      <CartesianGrid stroke="grey" strokeWidth={0.3} />
                      <XAxis
                        dataKey="name"
                        fontSize={10} fontWeight={600}
                        tickLine={false} axisLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        tickFormatter={(v) => v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v} yAxisId="left-axis"
                        //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}} 
                        // label={<AxisLabel axisType='yAxis' x={-30} y={-5}>Energy</AxisLabel>}
                        label={{ value: "Energy", angle: -90, position: "insideBottomLeft", fontSize: "2vmin", dx: -10 }}
                        dataKey={() => Math.ceil(energy?.data2?.reduce((acc, curr, index) => Math.max(curr.netEnergy, curr.normalisedEnergy, acc), -Infinity)) + 100000}
                        domain={[Math.floor(energy?.data2?.reduce((acc, curr, index) => Math.min(curr.netEnergy, curr.normalisedEnergy, acc), Infinity)) - 100000, 'dataMax']}
                        tickLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        yAxisId="right-axis"
                        orientation="right"
                        // label={<AxisLabel axisType='yAxis' x={26} y={305}>Excess/Shortfall</AxisLabel>}
                        label={{ value: "Excess/Shortfall", angle: -90, position: "insideBottomRight", fontSize: "2vmin", dy: -110, dx: 10 }}
                        tickLine={false} tickMargin={8}
                      />
                      <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["Net Energy", "Normalised Energy", "Shortfall"]} />} />
                      <Legend
                        content={<CustomizeLegend active={false} payload={[]} LegendValues={["Net Energy", "Normalised Energy", "Shortfall"]} data={showNetNormalisedShortfallData} setData={setShowNetNormalisedShortfallData} />}
                      />
                      <Bar
                        hide={showNetNormalisedShortfallData.netEnergy ? false : true}
                        dataKey="netEnergy"
                        fill={`${colors3[0]}`}
                        yAxisId="left-axis"
                      />
                      <Bar
                        hide={showNetNormalisedShortfallData.normalisedEnergy ? false : true}
                        dataKey="normalisedEnergy"
                        fill={`${colors3[1]}`}
                        yAxisId="left-axis"
                      />
                      <Line
                        hide={showNetNormalisedShortfallData.shortfall ? false : true}
                        type="monotone"
                        dataKey="shortfall"
                        strokeWidth={3}
                        stroke={`${colors1[4]}`}
                        yAxisId="right-axis"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                position={"relative"}
                bgcolor={"white"}
                // borderRadius={"14px"}
                padding={"1vmin"}
              >
                <div style={{ width: "100%", textAlign: "end", position: "absolute", right: "10px", top: "20px" }}>
                  <Printer clickhandler={() => downloadRef4.current.link.click()}
                    jpgDownload={() =>
                      setTimeout(async () => {
                        document.getElementsByClassName("cpy_right")[3].style.display = "block";
                        exportComponentAsJPEG(graphRef4, { fileName: "graph" })
                        document.getElementsByClassName("cpy_right")[3].style.display = "none";
                      }, 100)
                    }
                    svgDownload={async () => {
                      document.getElementsByClassName("cpy_right")[3].style.display = "block";
                      const svgData = await saveToSvg(graphRef4.current.container)
                      document.getElementsByClassName("cpy_right")[3].style.display = "none";
                      saveAs(svgData, 'graph.svg')
                    }}
                  />
                </div>
                <CSVLink
                  data={energy?.data3?.reduce((acc, curr) => {
                    acc.push({
                      Month: curr.name,
                      PVsystEnergy: curr.pvsyst_Energy,
                      ContractualEnergy: curr.contractual_energy,
                    })
                    return acc;
                  }, []) || []}
                  filename='data.csv'
                  className='hidden'
                  target='_blank'
                  ref={downloadRef4}
                />
                <h4 style={{ textAlign: "center", fontSize: "2vmin", padding: "1vmax 0" }}>
                  PVsyst Energy (KWh) vs Contratual Energy (KWh)
                </h4>
                <div style={{ width: "100%", height: "36vh" }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      margin={{
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 10,
                      }}
                      barCategoryGap={"20%"}
                      data={energy?.data3 || []}
                      ref={graphRef4}
                    >
                      <CartesianGrid stroke="grey" strokeWidth={0.3} />
                      <XAxis
                        dataKey="name"
                        fontSize={10} fontWeight={600}
                        tickLine={false} axisLine={false} tickMargin={8}
                      />
                      <YAxis
                        fontSize={"2vmin"}
                        tickFormatter={(v) => v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v} yAxisId="left-axis"
                        //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}} 
                        // label={<AxisLabel axisType='yAxis' x={-17} y={-7}>Energy</AxisLabel>}
                        dataKey={() => Math.ceil(energy?.data3?.reduce((acc, curr, index) => Math.max(curr.pvsyst_Energy, curr.contractual_energy, acc), -Infinity)) + 100000}
                        domain={[Math.floor(energy?.data3?.reduce((acc, curr, index) => Math.min(curr.pvsyst_Energy, curr.contractual_energy, acc), Infinity)) - 100000, 'dataMax']}
                        tickLine={false} tickMargin={8}
                      />
                      <Tooltip content={<CustomizeTootip active={false} payload={[]} label={""} TooltipValues={["PVsyst Energy", "Contractual Energy"]} />} />
                      <Legend
                        content={<CustomizeLegend active={false} payload={[]} LegendValues={["PVsyst Energy", "Contractual Energy"]} data={showPVsystContractualData} setData={setShowPVsystContractualData} />}
                      />
                      <Bar
                        hide={showPVsystContractualData.pvsystEnergy ? false : true}
                        dataKey="pvsyst_Energy"
                        fill={`${colors3[0]}`}
                        yAxisId="left-axis"
                      />
                      <Bar
                        hide={showPVsystContractualData.contractualEnergy ? false : true}
                        dataKey="contractual_energy"
                        fill={`${colors3[1]}`}
                        yAxisId="left-axis"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>

          { /* ###   Detailed Container 3 end ### */}

        </Grid>
      }
    </>
  );
};

export default Detailed;


